import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";

import { phlabeltype } from "../lib/storage/phlabel";
import { genericApi } from "../lib/genericAPI";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
    },
    summary: {},
  });

type InspectionPHLabelProps = {
  market: string;
  handleClose: () => void;
  handleConfirm: () => void;
} & WithStyles<typeof styles>;

const InspectionPHLabelUnstyled: React.SFC<InspectionPHLabelProps> = (props) => {
  const { classes, market, handleClose, handleConfirm } = props;
  const [phLabels, setPhLabels] = React.useState([]);

  useEffect(() => {
    const phlabelsGeneric = genericApi<phlabeltype>("phlabels");
    const resultArr = [];
    phlabelsGeneric.all().then((allData) => {
      if (allData && allData.length > 0) {
        allData.map((allDataItem) => {
          if (allDataItem.market == market) {
            resultArr.push(allDataItem);
          }
        });
        setPhLabels(resultArr);
      }
    });
  }, [classes]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Button
          key={`close`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "red" }}
          onClick={() => {
            handleClose();
          }}
        >
          <IconClose />
        </Button>
        <Button
          key={`check`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "green" }}
          onClick={() => {
            handleConfirm();
          }}
        >
          <IconCheck />
        </Button>
      </AppBar>
      {Detail(phLabels)}
    </div>
  );
};

export default withStyles(styles)(InspectionPHLabelUnstyled);

const Detail = (phlabels) => {
  const style = {
    tableheader: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "black",
    },
  };
  return (
    <div>
      <table>
        <tbody>
          <tr key={`dethead`}>
            <td style={style.tableheader}>{`ITEM`}</td>
            <td style={style.tableheader}>{`SUBSTANCE`}</td>
            <td style={style.tableheader}>{`WORDING`}</td>
          </tr>
          {phlabels.map((phitem) => {
            return (
              <tr key={`det${phitem.id}`}>
                <td style={style.tableheader}>{`${phitem.item}`}</td>
                <td style={style.tableheader}>{`${phitem.substance}`}</td>
                <td style={style.tableheader}>{`${phitem.wording}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

import { client, request } from "./_base";

export const inspectionorders = client("inspectionorders", item => ({
  ...item,
}));

export const ordersFindByInspectionId = id => {
  return request(`inspectionorders/ext/findByInspectionId/${id}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

import { genericApi } from "../genericAPI";

export type producerphtype = {
  id: string;
  orgid: number;
  producer_id: number;
  validfrom: Date;
  validto: Date;
  producerphtype_detail: string;
  commoditycode: string;
  exportmarket: string;
  mrl: string;
  activeingredient: string;
  product: string;
  waterapplication100: string;
  waxapplication100: string;
  comments: string;
};

export const producerphStorageName = "producerph";
export const producerphGetAll = async () => {
  const producerphGeneric = genericApi<producerphtype>(producerphStorageName);
  const producerphLocal = await producerphGeneric.all();
  return producerphLocal;
};

export const producerphCreate = async ({
  id,
  orgid,
  producer_id,
  validfrom,
  validto,
  producerphtype_detail,
  commoditycode,
  exportmarket,
  mrl,
  activeingredient,
  product,
  waterapplication100,
  waxapplication100,
  comments,
}) => {
  const producerphGeneric = genericApi<producerphtype>(producerphStorageName);
  producerphGeneric.create({
    id,
    orgid,
    producer_id,
    validfrom,
    validto,
    producerphtype_detail,
    commoditycode,
    exportmarket,
    mrl,
    activeingredient,
    product,
    waterapplication100,
    waxapplication100,
    comments,
  });
};

export const producerphRemove = async ({ id }) => {
  const producerphGeneric = genericApi<producerphtype>(producerphStorageName);
  producerphGeneric.remove(id);
};

import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
export const DialogInformation = ({
  isOpen,
  handleClose,
  handleOK,
  title,
  body,
}) => {
  const [x, setX] = React.useState("20");

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-slide-dialog-title"
        aria-describedby="alert-dialog-slide-description">

        <DialogTitle id="form-dialog-slide-title">
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {body}
          </DialogContentText>
        </DialogContent>
        
        <DialogActions>
          <Button disableFocusRipple={false}
            onClick={() => {
              handleOK(x);
            }} 
            color="primary" 
            variant="outlined" 
            autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

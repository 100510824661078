import React, { Fragment, useEffect } from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Form, Field } from "react-final-form";
import { useSnackbar } from "notistack";

import orchard from "./orchard.jpg";

import history from "./lib/history";
import { login, whoami } from "./lib/api/_base";
import { TextField } from "./lib/components/TextField";

import { genericApi } from "./lib/genericAPI";
import { userstype } from "./lib/storage/users";

import { UserContext } from "./lib/context/UserContext";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      background: `url(${orchard})`,
      backgroundSize: "cover",
    },
    img: {
      height: "100vh",
      width: "100vw",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100vh",
      backgroundColor: "rgba(255,255, 255, 0.8)",
      alignItems: "center",
      justifyContent: "center",
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonRegister: {
      alignItems: "right",
      justifyContent: "right",
    },
    inputs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 3,
    },
    input: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    errorBox: {
      margin: "10px",
      padding: "10px",
      fontWeight: "bold",
      backgroundColor: "pink",
      color: "black",
    },
  });

export type LoginProps = {} & WithStyles<typeof styles>;

const LoginUnstyled: React.SFC<LoginProps> = (props) => {
  const { classes } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, hydrate, loading } = React.useContext(UserContext);

  useEffect(() => {
    if (navigator.onLine) {
      // const currentuser = window.localStorage.getItem("currentUser");
      // if (currentuser && currentuser.length > 0) {
      //   const currentuserJSON = JSON.parse(currentuser);
      //   handleLogin(currentuserJSON);
      // }
      // else {
      whoami()
        .then((user) => {
          if (!user.username) {
            throw new Error("Not logged in");
          }
          history.push("/home");
        })
        .catch((e) => {
          history.push("/login");
        });
      // }
    }
  }, []);

  const SnackThrow = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };

  const action = (key) => (
    <Fragment>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        {"GOT IT"}
      </Button>
    </Fragment>
  );

  const handleLogin = (fields) => {
    if (navigator.onLine) {
      login(fields)
        .then((user) => {
          if (user.message) {
            SnackThrow(user.message, "error");
            return Promise.reject("Failure to login");
          }
          history.push("/");
          // user["password"] = fields.password;
          window.localStorage.setItem("currentUser", JSON.stringify(user));
          hydrate().then(() => {
            window.localStorage.setItem("loggedIn", "true");
          });
          return Promise.resolve();
        })
        .catch((e) => {
          window.localStorage.setItem("loggedIn", "false");
          history.push("/login");
          return Promise.resolve({
            name: "Username and/or password invalid",
          });
        });
    } else {
      alert("onSubmit-navigator.offline");
      const usersGeneric = genericApi<userstype>("users");
      usersGeneric.all().then((resultUsers) => {
        if (!resultUsers || resultUsers.length == 0) {
          SnackThrow("No local user info found. Network connection required", "error");
          return;
        }
        const userexist = resultUsers.find((user) => {
          return user.username == fields.username;
        });
        if (userexist && userexist.username.length != 0) {
          window.localStorage.setItem("currentUser", JSON.stringify(userexist));
          SnackThrow("Logged in", "success");
          history.push("/");
          return;
        } else {
          SnackThrow("User name / password incorrect", "information");
          history.push("/login");
          return;
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.root}>
        <Form
          onSubmit={(fields: any) => {
            handleLogin(fields);
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.buttons}>
                <Typography variant="headline">Impala Login</Typography>
              </div>
              <div className={classes.inputs}>
                <Field className={classes.input} name="username" label="Username" component={TextField} />
                <Field className={classes.input} name="password" label="Password" type="password" component={TextField} />
              </div>
              <div className={classes.buttons}>
                <Button variant="contained" color="primary" type="submit">
                  Login
                </Button>
              </div>
            </form>
          )}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(LoginUnstyled);

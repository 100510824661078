import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
export const Confirmation = ({
  children = undefined,
  isOpen,
  handleClose,
  handleConfirm,
  title,
  body,
  fullscreen = false,
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-slide-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen={fullscreen}
      >
        {title && title.length > 0 &&
          <DialogTitle id="form-dialog-slide-title">
            {title}
          </DialogTitle>
        }

        {body && 
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {body}
            </DialogContentText>
          </DialogContent>
        }

        <div style={{ padding: "10px" }}>
          {children}
        </div>

        {!children && 
          <DialogActions>
            <Button disableFocusRipple={false}
              onClick={handleClose} 
              color="secondary" 
              variant="outlined" 
              autoFocus
            >
              no
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="outlined" 
            >
              yes
            </Button>
          </DialogActions>
        }
      </Dialog>
    </div>
  );
};
import React, { useState, useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import IconCheck from "@material-ui/icons/Check";
import IconDelete from "@material-ui/icons/Delete";
import IconLaunch from "@material-ui/icons/Launch";
import IconDone from "@material-ui/icons/Done";
import Tooltip from "@material-ui/core/Tooltip";

import { notify } from "../lib/api/notify";
import { findByNumber } from "../lib/api/inspection";
import { piorder } from "../lib/api/piorder";

import InspectionsFullView from "../inspections/inspectionsfull";
import { isNullOrUndefined } from "util";

import { UserContext } from "../lib/context/UserContext";

import moment from "moment";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // width: "inherit",
      margin: "0",
    },
    actions: {
      width: "100%",
      hieght: "50px",
      textAlign: "right",
    },
    stutusBtn: {
      marginLeft: "5px",
    },
    commentHeader: {
      color: "#fff",
      margin: "0",
      // width: "inherit",
      backgroundColor: "#3f51b5",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    commentBody: {
      margin: "10px",
      padding: "10px",
    },
    commentBox: {
      margin: "-5px 0px 10px 0px",
    },
    inspectionRefBox: {
      float: "left",
      textAlign: "left",
      paddingTop: "8px",
      paddingBottom: "20px",
      color: "darkgreen",
      fontSize: "0.9em"
    }
  });

type NotificationsCardProps = {
  notification: { id: number; comment: string; type: number; typeref: string; userfrom: string; status: number; created_at: Date; userto: string; user_id: number };
  reloadData: any;
} & WithStyles<typeof styles>;

const statuses = {
  0: "New",
  1: "Completed",
  2: "Deleted",
};

const NotificationsCardUnstyled: React.SFC<NotificationsCardProps> = (props) => {
  const { classes, reloadData } = props;
  const { id, comment, type, typeref, userfrom, userto, status, created_at, user_id } = props.notification;
  const [selectedInspection, setSelectedInspection] = React.useState();
  const [selectedInspectionRef, setSelectedInspectionRef] = React.useState();

  const { user } = React.useContext(UserContext);

  React.useEffect(() => { getInspectionReference() }, [typeref]);

  const updateToComplete = () => {
    notify.update(id, {
      data: {
        status: 1,
      },
    });
    reloadData();
  };

  const updateToDelete = () => {
    notify.update(id, {
      data: {
        status: 2,
      },
    });
    reloadData();
  };

  const viewAction = () => {
    if (type == 1) {
      findByNumber(Number(typeref)).then((data) => {
        if (data && data.length > 0) {
          setSelectedInspection(data[0]);
        }
      });
    }
  };

  const viewActionClose = () => {
    if (type == 1) {
      setSelectedInspection(undefined);
    }
  };

  const getInspectionReference = async () => {
    const inspectionsData = await findByNumber(Number(typeref));
    const inspection = inspectionsData.find((i) => i.number == typeref);
    const pio = await piorder.single(inspection.piorder);
    setSelectedInspectionRef(`${pio.ordernum} (${inspection.pickref})`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.commentHeader}>
        {/* <Tooltip title={`Status: ${statuses[status]}`}> */}
        <>
          {status == 1 && <IconDone style={{ marginRight: "10px", fontSize: "1em" }} />}
          {status == 2 && <IconDelete style={{ marginRight: "10px", fontSize: "1em" }} />}
        </>
        {/* </Tooltip> */}
        <Typography variant="subtitle1" color="inherit" style={{ marginRight: "0.5em" }}>
          From: <span style={{ fontWeight: "bold" }}>{userfrom}</span>
        </Typography>
        <Typography variant="subtitle1" color="inherit" style={{ marginRight: "0.5em" }}>
          To: <span style={{ fontWeight: "bold" }}>{userto}</span>
        </Typography>
        <Typography variant="subtitle1" color="inherit" style={{ flex: "1", textAlign: "right" }}>
          {moment(created_at).format("DD/MM/YYYY HH:mm")}
        </Typography>
      </div>
      <div className={classes.commentBody}>
        <div className={classes.commentBox}>
          {comment.split("\n").map((item, index) => {
            return <p key={`comment${index}`}>{item}</p>;
          })}
        </div>
        {status == 0 ? (
          <div className={classes.actions}>
            <div className={classes.inspectionRefBox}><span>{selectedInspectionRef}</span></div>
            {type == 1 && (
              <Button title={`View ${selectedInspectionRef} summary`} variant="contained" color="primary" onClick={viewAction}>
                <IconLaunch />
              </Button>
            )}
            {user_id == user.id && (
              <Button
                title="Complete"
                className={classes.stutusBtn}
                style={{ cursor: "pointer", backgroundColor: "orange", color: "white" }}
                variant="contained"
                onClick={updateToComplete}
              >
                <IconCheck />
              </Button>
            )}
          </div>
        ) : (
            <div className={classes.actions}>
              <div className={classes.inspectionRefBox}><span>{selectedInspectionRef}</span></div>
              {type == 1 && (
                <Button title={`View ${selectedInspectionRef} summary`} variant="contained" color="primary" onClick={viewAction}>
                  <IconLaunch />
                </Button>
              )}
              {status == 1 && user_id == user.id && (
                <Button title="Delete" className={classes.stutusBtn} color="secondary" variant="contained" onClick={updateToDelete}>
                  <IconDelete />
                </Button>
              )}
            </div>
          )}
        {selectedInspection && <InspectionsFullView isOpen={!isNullOrUndefined(selectedInspection)} handleClose={viewActionClose} inspection={selectedInspection} />}
      </div>
    </div>
  );
};

export default withStyles(styles)(NotificationsCardUnstyled);

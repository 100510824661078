import React, { useEffect, Fragment } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { useSnackbar } from "notistack";

import moment from "moment";

import { genericApi } from "../lib/genericAPI";
import { userstype } from "../lib/storage/users";
import { inspectionlabelreqtype } from "../lib/storage/inspectionlabelrequirements";
import { inspectiontype } from "../lib/storage/inspection";
import { orchardtype } from "../lib/storage/orchard";
import { producertype } from "../lib/storage/producers";
import { puctype } from "../lib/storage/pucs";
import { piordergroupedtype } from "../lib/storage/piordergrouped";
import { piinstructiontype } from "../lib/storage/piinstructions";
import { phtreatmenttype } from "../lib/storage/phtreatment";
import { targetmarkettype } from "../lib/storage/targetmarket";
import { phlabeltype } from "../lib/storage/phlabel";
import { specialmarkettype } from "../lib/storage/specialmarket";
import { varietiestype } from "../lib/storage/varieties";
import { weekstype } from "../lib/storage/week";
import { labtesttype } from "../lib/storage/labtest";
import { producerphtype, producerphStorageName } from "../lib/storage/producerph";

import { producerph, getProducerPHFullDetail, GetProducerPHFullDetailType } from "../lib/api/producerph";
import { labtest, labtestFull } from "../lib/api/labtest";
import { users } from "../lib/api/users";
import { producers } from "../lib/api/producer";
import { pucs } from "../lib/api/producerpuc";
import { orchards } from "../lib/api/producerorchard";
import { target, targetsDistinctMarketsCountries } from "../lib/api/target";
import { getOrdersGrouped, piorder, getOrders } from "../lib/api/piorder";
import { inspections, findByNumber } from "../lib/api/inspection";
import { inspectionorders } from "../lib/api/inspectionorders";
import { inspectionactions } from "../lib/api/inspectionactions";
import { phtreatment } from "../lib/api/phtreatment";
import { phlabel } from "../lib/api/phlabel";
import { inspectionlabelrequirements } from "../lib/api/inspectionlabelrequirement";
import { specialmarkets } from "../lib/api/specialmarket";
import { varietyFull } from "../lib/api/variety";
import { weeks } from "../lib/api/week";

import LZString from "lz-string";
import { palletspecFull, palletspecs, palletspectype } from "../lib/api/palletspec";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      padding: theme.spacing.unit,
    },
  });

export type SyncProps = {} & WithStyles<typeof styles>;

export const SyncUnstyled: React.SFC<SyncProps> = (props) => {
  const { classes } = props;
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("loggedIn") == "true") {
      synchronize();
    }
    setInterval(() => {
      try {
        if (localStorage.getItem("loggedIn") == "true") {
          synchronize();
        }
      } catch {}
    }, 60000);
    setInterval(() => {
      try {
        if (localStorage.getItem("loggedIn") == "true") {
          synchronizeInspections();
        }
      } catch {}
    }, 1000);
  }, [classes]);

  const synchronize = async () => {
    if (navigator.onLine) {
      const lastSync = localStorage.getItem("lastSync") == null ? 0 : localStorage.getItem("lastSync");
      localStorage.setItem("lastSync", moment().utc().valueOf().toString());

      checkUsers(lastSync);
      checkProducers(lastSync);
      checkPuc(lastSync);
      checkOrchard(lastSync);
      checkTargets(lastSync);
      checkPHTreatment(lastSync);
      checkPHLabels(lastSync);
      checkInspectionLabelRequirements(lastSync);
      checkSpecialMarket(lastSync);
      checkVarieties(lastSync);
      checkWeeks(lastSync);
      checkLabTest(lastSync);
      checkProducerPH(lastSync);
      checkOrdersGrouped(lastSync);
    }
  };

  const synchronizeInspections = async () => {
    if (navigator.onLine) {
      pushInspections().then(() => {
        validateInspectionSync();
      });
    }
  };

  const checkUsers = (lastSync) => {
    users.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("users");
        const usersGeneric = genericApi<userstype>("users");
        if (navigator.onLine) {
          users.all().then((data) => {
            data.map((item) => {
              usersGeneric.create({ id: item.id, username: item.username, admin: item.admin });
            });
            SnackThrow("Synchronized Users", "success");
          });
        }
      }
    });
  };

  const checkProducers = (lastSync) => {
    producers.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("producers");
        const producersGeneric = genericApi<producertype>("producers");
        if (navigator.onLine) {
          producers.all().then((data) => {
            data.map((item) => {
              producersGeneric.create({ id: item.id, orgid: item.id, code: item.code, name: item.name });
            });
            SnackThrow("Synchronized Producers", "success");
          });
        }
      }
    });
  };

  const checkPuc = (lastSync) => {
    pucs.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("pucs");
        const pucsGeneric = genericApi<puctype>("pucs");
        if (navigator.onLine) {
          pucs.all().then((data) => {
            data.map((item) => {
              pucsGeneric.create({ id: item.id, orgid: item.id, producer_id: item.producer_id, code: item.code });
            });
            SnackThrow("Synchronized Puc", "success");
          });
        }
      }
    });
  };

  const checkOrchard = (lastSync) => {
    orchards.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("orchards");
        const orchardsGeneric = genericApi<orchardtype>("orchards");
        if (navigator.onLine) {
          orchards.all().then((data) => {
            data.map((item) => {
              orchardsGeneric.create({ id: item.id, orgid: item.id, puc_id: item.puc_id, code: item.code });
            });
            SnackThrow("Synchronized Orchard", "success");
          });
        }
      }
    });
  };

  const checkTargets = (lastSync) => {
    target.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("targetmarkets");
        const targetmarketsGeneric = genericApi<targetmarkettype>("targetmarkets");
        if (navigator.onLine) {
          targetsDistinctMarketsCountries().then((result) => {
            result.data.map((item) => {
              targetmarketsGeneric.create({ id: item.id, market: item.market, country: item.country });
            });
            SnackThrow("Synchronized Targets", "success");
          });
        }
      }
    });
  };

  const checkPHTreatment = (lastSync) => {
    phtreatment.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("phtreatments");
        const phtreatmentsGeneric = genericApi<phtreatmenttype>("phtreatments");
        if (navigator.onLine) {
          phtreatment.all().then((result) => {
            result.map((item) => {
              phtreatmentsGeneric.create({
                id: item.id,
                commoditycode: item.commoditycode,
                exportmarket: item.exportmarket,
                mrl: item.mrl,
                activeingredient: item.activeingredient,
                product: item.product,
                waterapplication100: item.waterapplication100,
                waxapplication100: item.waxapplication100,
                comments: item.comments,
              });
            });
            SnackThrow("Synchronized PHTreatments", "success");
          });
        }
      }
    });
  };

  const checkPHLabels = (lastSync) => {
    phlabel.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("phlabels");
        const phlabelsGeneric = genericApi<phlabeltype>("phlabels");
        if (navigator.onLine) {
          phlabel.all().then((result) => {
            result.map((item) => {
              phlabelsGeneric.create({ id: item.id, market: item.market, item: item.item, substance: item.substance, wording: item.wording });
            });
            SnackThrow("Synchronized PHLabels", "success");
          });
        }
      }
    });
  };

  const checkInspectionLabelRequirements = (lastSync) => {
    inspectionlabelrequirements.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("inspectionlabelreqs");
        const inspectionlabelreqsGeneric = genericApi<inspectionlabelreqtype>("inspectionlabelreqs");
        if (navigator.onLine) {
          inspectionlabelrequirements.all().then((result) => {
            result.map((item) => {
              inspectionlabelreqsGeneric.create({
                id: item.id,
                market: item.market,
                country: item.country,
                requiredconsignee: item.requiredconsignee,
                requiredcountry: item.requiredcountry,
              });
            });
            SnackThrow("Synchronized Label Requirements", "success");
          });
        }
      }
    });
  };

  const checkSpecialMarket = (lastSync) => {
    specialmarkets.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("specialmarkets");
        const specialmarketsGeneric = genericApi<specialmarkettype>("specialmarkets");
        if (navigator.onLine) {
          specialmarkets.all().then((data) => {
            data.map((item) => {
              specialmarketsGeneric.create({ id: item.id, market: item.market });
            });
            SnackThrow("Synchronized Special Markets", "success");
          });
        }
      }
    });
  };

  const checkVarieties = (lastSync) => {
    if (lastSync == 0) {
      localStorage.removeItem("varieties");
      const varietiesGeneric = genericApi<varietiestype>("varieties");
      if (navigator.onLine) {
        varietyFull().then((result) => {
          result.data.map((item) => {
            varietiesGeneric.create({
              id: item.id,
              orgid: item.id,
              code: item.code,
              name: item.name,
              commodity_id: item.commodity_id,
              commodity: item.commodity,
              commodity_name: item.commodity_name,
            });
          });
          SnackThrow("Synchronized Varieties", "success");
        });
      }
    }
  };

  const checkWeeks = (lastSync) => {
    weeks.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("weeks");
        const weeksGeneric = genericApi<weekstype>("weeks");
        if (navigator.onLine) {
          weeks.all().then((data) => {
            data.map((item) => {
              weeksGeneric.create({ id: item.id, orgid: item.id, week: item.week, period_start: item.period_start, period_end: item.period_end });
            });
            SnackThrow("Synchronized weeks", "success");
          });
        }
      }
    });
  };

  const checkLabTest = (lastSync) => {
    labtest.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("labtest");
        const labtestGeneric = genericApi<labtesttype>("labtest");
        if (navigator.onLine) {
          labtestFull().then((result) => {
            result.data.map((item) => {
              labtestGeneric.create({
                id: item.id,
                orgid: item.id,
                sampledate: item.sampledate,
                sampledateend: item.sampledateend,
                lab: item.lab,
                producer_id: item.producer_id,
                producer_code: item.producer_code,
                producer_name: item.producer_name,
                puc_id: item.puc_id,
                producerpuc_code: item.producerpuc_code,
                orchard_id: item.orchard_id,
                producerorchard_code: item.producerorchard_code,
                variety_id: item.variety_id,
                variety_code: item.variety_code,
                variety_name: item.variety_name,
                labtesttype_id: item.labtesttype_id,
                labtesttype_detail: item.labtesttype_detail,
                approved: item.approved,
              });
            });
            SnackThrow("Synchronized labtest", "success");
          });
        }
      }
    });
  };

  const checkProducerPH = (lastSync) => {
    producerph.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem(producerphStorageName);
        const producerphGeneric = genericApi<producerphtype>(producerphStorageName);
        if (navigator.onLine) {
          getProducerPHFullDetail(0).then((result: [GetProducerPHFullDetailType]) => {
            result.map((item) => {
              producerphGeneric.create({
                id: item.id.toString(),
                orgid: item.id,
                producer_id: item.producer_id,
                validfrom: item.validfrom,
                validto: item.validto,
                producerphtype_detail: item.producerphtype_detail,
                commoditycode: item.commoditycode,
                exportmarket: item.exportmarket,
                mrl: item.mrl,
                activeingredient: item.activeingredient,
                product: item.product,
                waterapplication100: item.waterapplication100,
                waxapplication100: item.waxapplication100,
                comments: item.comments,
              });
            });
            SnackThrow("Synchronized Producer PHTreatments", "success");
          });
        }
      }
    });
  };

  const checkOrdersGrouped = (lastSync) => {
    piorder.newer(lastSync).then((newData) => {
      if (newData.data && newData.data.length > 0) {
        localStorage.removeItem("piordergrouped");
        localStorage.removeItem("piinstructions");
        const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
        if (navigator.onLine) {
          getOrdersGrouped().then((result) => {
            const orderidlist = [];
            result.data.map((item) => {
              orderidlist.push(item.id);
              piordergroupedGeneric.create({
                id: item.id,
                orgid: item.id,
                producer_id: item.producer_id,
                producer_name: item.producer_name,
                variety: item.variety,
                variety_id: item.variety_id,
                week_id: item.week_id,
                week: item.week,
                revision: item.revision,
                ordernum: item.ordernum,
                commodity: item.commodity,
                updated_at: item.updated_at,
                exchange: item.exchange,
                groupnumber: item.groupnumber,
                exworks: item.exworks,
                fixed: item.fixed,
                local: item.local,
                printdate: item.printdate,
                farm: item.farm,
                comment: item.comment,
              });
            });
            SnackThrow("Synchronized OrdersGrouped", "success");
            // get fill details list
            getOrdersFullDetail(orderidlist);
            getPalletSpecFull();
          });
        }
      }
    });
  };

  const getPalletSpecFull = () => {
    localStorage.removeItem("palletspecs");
    const palletspecGeneric = genericApi<palletspectype>("palletspecs");
    if (navigator.onLine) {
      palletspecFull().then((result) => {
        for (let i = 0; i < result.data.length; i++) {
          const item = result.data[i];
          palletspecGeneric.create({
            id: item.id,
            orgid: item.id,
            carton: item.carton,
            code: item.code,
            weight: item.weight,
          });
        }
        SnackThrow("Synchronized Palletspecs", "success");
      });
    }
  };

  const getOrdersFullDetail = (idlist) => {
    const idliststring = idlist.join(",");
    const piinstructionGeneric = genericApi<piinstructiontype>("piinstructions");
    if (navigator.onLine) {
      // const piinstructionsArr = [];
      getOrders(idliststring).then((result) => {
        result.data.map((item) => {
          // piinstructionsArr.push({
          piinstructionGeneric.create({
            id: item.id,
            orgid: item.id,
            OrderNumber: item.OrderNumber,
            Variety_Name: item.Variety_Name,
            Variety_Code: item.Variety_Code,
            piinstruction_id: item.piinstruction_id,
            groupnum: item.groupnum,
            groupline: item.groupline,
            target_region: item.target_region,
            target_market: item.target_market,
            target_country: item.target_country,
            target_currency: item.target_currency,
            classv: item.class,
            count: item.count,
            Palletspec_Code: item.Palletspec_Code,
            Palletspec_Carton: item.Palletspec_Carton,
            brand: item.brand,
            inventory_code: item.inventory_code,
            coldroom: item.coldroom,
            Palletspec_Id: item.Palletspec_Id,
            rowKey: item.rowKey,
            currency: item.target_currency,
            inventory_description: item.inventory_description,
            mgp: item.mgp,
            fob: item.fob,
            rangemin: item.rangemin,
            rangemax: item.rangemax,
            dip: item.dip,
            dipmin: item.dipmin,
            dipmax: item.dipmax,
            volume: item.volume,
            comment: item.comment,
            Palletspec_Name: item.Palletspec_Name,
          });
        });
        // piinstructionGeneric.create(piinstructionsArr);
        SnackThrow("Synchronized PI Details", "success");
      });
    }
  };

  const pushInspections = async () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric
      .filterBy("processed", 1)
      .then((data) => {
        if (data && data.length > 0) {
          data.map((inspectionitem) => {
            inspectionsGeneric.update(inspectionitem.id, { ...inspectionitem, processed: 2 });

            const newInspectionData = {
              data: {
                number: inspectionitem.id,
                inspectiondate: inspectionitem.inspectiondate,
                producer: inspectionitem.producer,
                puc: inspectionitem.puc,
                orchard: inspectionitem.orchard,
                specialmarket: inspectionitem.specialmarket,
                pickref: inspectionitem.pickref,
                brix: inspectionitem.brix,
                acid: inspectionitem.acid,
                packedmarket: inspectionitem.packedmarket,
                packedcountry: inspectionitem.packedcountry,
                postharvesttreatment: inspectionitem.postharvesttreatment,
                postharvestlabel: inspectionitem.postharvestlabel,
                labelconsignee: inspectionitem.labelconsignee,
                labelcountry: inspectionitem.labelcountry,
                piorder: inspectionitem.piorder,
                facility: inspectionitem.facility,
                juice: inspectionitem.juice,
                username: inspectionitem.username,
                groupnumber: inspectionitem.groupnumber,
                labtest: inspectionitem.labtest,
                degreen: inspectionitem.degreen,
                flag: inspectionitem.flag,
              },
            };

            inspections.create(newInspectionData).then((newid) => {
              if (!newid[0]) {
                SnackThrow(`could not push inspection - ${newInspectionData.data.pickref}`, "error");
                return false;
              }

              if (inspectionitem.inspectionOrders && inspectionitem.inspectionOrders.length > 0) {
                inspectionitem.inspectionOrders.map((inspectionordersitem) => {
                  const newInspectionOrdersData = {
                    data: {
                      inspection_id: newid[0],
                      piinstruction_id: inspectionordersitem.piinstruction_id,
                      labelcarton: inspectionordersitem.labelcarton,
                      inventory: inspectionordersitem.inventory,
                      weight: inspectionordersitem.weight,
                      firmness: inspectionordersitem.firmness,
                      buttons: inspectionordersitem.buttons,
                      grading: inspectionordersitem.grading,
                      shape: inspectionordersitem.shape,
                      sizing: inspectionordersitem.sizing,
                      color: inspectionordersitem.color,
                      blemish: inspectionordersitem.blemish,
                      skintexture: inspectionordersitem.skintexture,
                      granulation: inspectionordersitem.granulation,
                      general: inspectionordersitem.general,
                      proposedmarket: inspectionordersitem.proposedmarket,
                      proposedcountry: inspectionordersitem.proposedcountry,
                      fruitspec: inspectionordersitem.fruitspec,
                    },
                  };

                  inspectionorders.create(newInspectionOrdersData).then((newid) => {
                    if (!newid[0]) {
                      SnackThrow(`could not push inspection order item`, "error");
                      return false;
                    }
                  });
                });
              }

              if (inspectionitem.actions && inspectionitem.actions.length > 0) {
                inspectionitem.actions.map((inspectionactionsitem) => {
                  const actionsImagesArr = inspectionactionsitem.images.map((imageItem) => {
                    return { ...imageItem, base64: LZString.decompress(imageItem.base64) };
                  });
                  const imagesJSON = JSON.stringify(actionsImagesArr);

                  const newInspectionActionsData = {
                    data: {
                      inspection_id: newid[0],
                      piinstruction_id: inspectionactionsitem.piinstruction_id,
                      field: inspectionactionsitem.field,
                      comments: inspectionactionsitem.comments,
                      images: imagesJSON, // JSON.stringify(inspectionactionsitem.images),
                    },
                  };
                  inspectionactions.create(newInspectionActionsData).then((newid) => {
                    if (!newid[0]) {
                      SnackThrow(`could not push inspection action item`, "error");
                      return false;
                    }
                  });
                });
              }
            });
          });
        }
      })
      .catch((err) => {
        SnackThrow(`ISSUES found`, "error");
      });
  };

  const validateInspectionSync = () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.filterBy("processed", 2).then((datalocal) => {
      if (datalocal && datalocal.length > 0) {
        datalocal.map(async (itemlocal) => {
          const order = await piorder.single(itemlocal.piorder);
          findByNumber(itemlocal.id).then((datasql) => {
            if (datasql && datasql.length > 0) {
              if (!itemlocal.inspectionOrders || datasql[0].orderscount == itemlocal.inspectionOrders.length) {
                if (!itemlocal.actions || datasql[0].actionscount == itemlocal.actions.length) {
                  inspectionsGeneric.remove(itemlocal.id).then(() => {
                    if (itemlocal.editReference) {
                      const dataToUpdate = {
                        data: {
                          status: 3,
                        },
                      };
                      inspections.update(itemlocal.editReference, dataToUpdate);
                    }
                  });
                  SnackThrow(`Inspection Synchronized - QC${order.ordernum}`, "success");
                  // history.push("/");
                }
              }
            }
          });
        });
      }
    });
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const SnackThrow = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };

  const action = (key) => (
    <Fragment>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        {"GOT IT"}
      </Button>
    </Fragment>
  );

  return <div className={classes.root}>{loading && <CircularProgress style={{ color: "orange" }} />}</div>;
};

export default withStyles(styles)(SyncUnstyled);

import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import StepContent from '@material-ui/core/StepContent';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
// import Typography from '@material-ui/core/Typography';

import "react-datepicker/dist/react-datepicker.css";

import Inspection1 from "./inspection1";
import Inspection2 from "./inspection2";
import Inspection4 from "./inspection4";
import InspectionNotify from "./inspectionNotify";

import { genericApi } from "../lib/genericAPI";
import { inspectiontype, newInspectionType } from "../lib/storage/inspection";
import { inspections } from "../lib/api/inspection";
import { notify } from "../lib/api/notify";

import history from "../lib/history";
import { DialogInformation } from "../lib/components/dialoginformation";
import { Confirmation } from "../lib/components/confirmation";
import { piordergroupedtype } from "../lib/storage/piordergrouped";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100% + 60px)",
    },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginTop: theme.spacing.unit * 2,
    },
    resetContainer: {
      paddingLeft: theme.spacing.unit,
    },
  });

const InspectionUnstyled: React.SFC<any> = ({ match, classes }) => {
  const [inspectionID, setInspectionID] = React.useState(undefined);
  const [groupnumber, setGroupnumber] = React.useState(undefined);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["", ""];

  const [incomplete, setIncomplete] = React.useState(false);
  const [incompleteTitle, setIncompleteTitle] = React.useState("");
  const [incompleteBody, setIncompleteBody] = React.useState("");

  const [confirmComplete, setConfirmComplete] = React.useState(false);
  const [confirmMarketPlus, setConfirmMarketPlus] = React.useState(false);
  const [detailString, setDetailString] = React.useState("");
  const [notifyView, setNotifyView] = React.useState(false);

  useEffect(() => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.all().then((data) => {
      data.map((item) => {
        if (!item.producer || !item.pickref) {
          inspectionsGeneric.remove(item.id);
        }
      });
    });

    const currentinspection = localStorage.getItem("currentinspection");

    if (match.params.id == "0" && !currentinspection) {
      // get inspection from group number sent down.
      const matchGroupNumber = match.params.groupnum;

      if (matchGroupNumber) {
        setGroupnumber(matchGroupNumber);
        const inspectionsGeneric = genericApi<inspectiontype>("inspections");
        inspectionsGeneric.findBy("id", inspectionID).then((data) => {
          const newData = {
            ...data,
            groupnumber: matchGroupNumber,
            id: undefined,
            inspectionOrders: undefined,
            actions: undefined,
            packedcountry: undefined,
            packedmarket: undefined,
            // brix: 0,
            // acid: 0,
            facility: undefined,
            postharvestlabel: undefined,
            postharvesttreatment: undefined,
            labelconsignee: undefined,
            labelcountry: undefined,
            labtest: undefined,
          };
          inspectionsGeneric.create(newData).then((newId) => {
            setInspectionID(newId);
            setActiveStep(1);
            localStorage.setItem("currentinspection", newId.toString());
            history.push(`/inspection/${newId}`);
          });
        });
      } else {
        getLatestWeekAvailable().then((data: number) => {
          const newInspection = { ...newInspectionType, week_id: data };
          inspectionsGeneric.create(newInspection).then((newId: string) => {
            setInspectionID(newId);
            setActiveStep(0);
            localStorage.setItem("currentinspection", newId.toString());
          });
        });
      }
    } else {
      if (!currentinspection && match.params.id != currentinspection) {
        localStorage.setItem("currentinspection", match.params.id);
        setInspectionID(Number(match.params.id));
        getGroupNumber(Number(match.params.id));
      } else {
        history.push(`/inspection/${currentinspection}`);
        setInspectionID(Number(match.params.id));
        getGroupNumber(Number(match.params.id));
      }
    }
  }, [match.url, history.length]);

  // FUNCTIONS
  const getLatestWeekAvailable = async () => {
    return new Promise((resolve, reject) => {
      const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
      piordergroupedGeneric.all().then((allData) => {
        const sortedData = allData.sort((a, b) => {
          if (Number(a.week.replace(" ", "")) > Number(b.week.replace(" ", ""))) return -1;
          if (Number(a.week.replace(" ", "")) < Number(b.week.replace(" ", ""))) return 1;
          return 1;
        });

        if (sortedData && sortedData.length > 0) {
          resolve(sortedData[0].week_id);
        } else {
          resolve(78);
        }
      });
    });
  };

  const handleIncompleteClose = () => {
    setIncomplete(false);
  };

  const getGroupNumber = (inspectionid) => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      if (data) {
        setGroupnumber(data.groupnumber);
      }
    });
  };

  // STEPPER
  const handleNext = async () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionID).then((data) => {
      if (activeStep == 0) {
        setIncompleteTitle("STEP 1 VALIDATION");
        if (!data) {
          setIncompleteBody("Please add information");
          setIncomplete(true);
          return;
        }
        if (!data.producer) {
          setIncompleteBody("Producer Selection Required");
          setIncomplete(true);
          return;
        }
        if (!data.pickref || data.pickref.length == 0) {
          setIncompleteBody("PICKREF Required");
          setIncomplete(true);
          return;
        }
        setActiveStep((activeStep) => activeStep + 1);
        return;
      } else if (activeStep == 1) {
        setIncompleteTitle("STEP 2 VALIDATION");
        if (!data.piorder) {
          setIncompleteBody("Please select a PI");
          setIncomplete(true);
          return;
        }
        if (!data.packedmarket || data.packedmarket.length == 0) {
          setIncompleteBody("a Packed Market selection is required");
          setIncomplete(true);
          return;
        }
        if (!data.packedcountry || data.packedcountry.length == 0) {
          setIncompleteBody("a Packed Country selection is required");
          setIncomplete(true);
          return;
        }
        setActiveStep((activeStep) => activeStep + 1);
        return;
      } else {
        setActiveStep((activeStep) => activeStep + 1);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  const handleReset = () => {
    setActiveStep(1);
  };

  const handleGetStepContent = (index) => {
    switch (index) {
      case 0:
        return (
          <div>
            <Inspection1 inspectionid={inspectionID} />
          </div>
        );
        break;
      case 1:
        return (
          <div>
            <Inspection2 inspectionid={inspectionID} />
          </div>
        );
        break;
      default:
        break;
    }
  };

  const handleReturn = () => {
    localStorage.removeItem("currentinspection");
    history.push("/");
  };

  const handleComplete = () => {
    setpiOrderNumber();
    setConfirmComplete(true);
  };

  const handleCompleteClose = () => {
    setConfirmComplete(false);
  };

  const handleCompleteConfirm = () => {
    setConfirmComplete(false);
    handleProcess();
  };

  const handleMarketPlus = () => {
    setConfirmMarketPlus(true);
  };

  const handleMarketPlusClose = () => {
    setConfirmMarketPlus(false);
  };

  const handleMarketPlusConfirm = () => {
    setConfirmMarketPlus(false);
    localStorage.removeItem("currentinspection");
    // history.push("/");
    history.push(`/inspection/0/${groupnumber ? groupnumber : inspectionID}`);
  };

  const handleProcess = () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionID).then((data) => {
      const newData = {
        processed: 1,
      };
      inspectionsGeneric.update(inspectionID, { ...data, ...newData }).then(() => {});
    });
    localStorage.removeItem("currentinspection");
    history.push("/");
  };

  const setpiOrderNumber = () => {
    let ordernum = JSON.parse(localStorage.getItem("inspections"))[localStorage.getItem("currentinspection")].piorder_ordernum;
    if (ordernum) {
      setDetailString(ordernum);
      return;
    }
    const inspectionsGenerics = genericApi<inspectiontype>("inspections");
    inspectionsGenerics.findBy("id", inspectionID).then((data) => {
      const piorderId: any = data.piorder; // does not accept number !?!
      const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
      piordergroupedGeneric.findBy("orgid", piorderId).then((ordData) => {
        setDetailString(ordData.ordernum);
      });
    });
  };

  const handleNotify = () => {
    setNotifyView(true);
  };

  const handleNotifyClose = () => {
    setNotifyView(false);
  };

  const handleNotifyConfirm = (e, item) => {
    setNotifyView(false);
    notify.create({ data: { ...item } });
  };

  // EVENTS
  return (
    <div className={classes.root}>
      {activeStep != steps.length && (
        <div>
          <Stepper activeStep={activeStep} orientation="horizontal">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                {/* <StepContent style={{ padding: "0px" }}>
                </StepContent> */}
              </Step>
            ))}
          </Stepper>
          {handleGetStepContent(activeStep)}
          <div className={classes.actionsContainer}>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                {/* {activeStep === steps.length - 1 ? 'Start Inspection' : 'Next'} */}
                {`Next`}
              </Button>
              <Button variant="contained" onClick={handleNotify} className={classes.button} style={{ backgroundColor: "orange" }}>
                {`create notification`}
              </Button>
            </div>
          </div>
        </div>
      )}
      {activeStep === steps.length && (
        <div style={{ height: "100%" }}>
          <Paper square elevation={0} className={classes.resetContainer}>
            {/* <Typography>All setup steps completed - you&apos;re inspecting orders</Typography> */}
            <Button onClick={handleReset} className={classes.button} style={{ backgroundColor: "orange" }}>
              back
            </Button>
            <Button onClick={handleMarketPlus} className={classes.button} style={{ backgroundColor: "green", color: "white" }}>
              + market
            </Button>
            <Button onClick={handleReturn} className={classes.button} style={{ backgroundColor: "green", color: "white" }}>
              home
            </Button>
            <Button onClick={handleComplete} variant="contained" color="primary" className={classes.button}>
              complete
            </Button>
          </Paper>
          <Inspection4 inspectionid={inspectionID} />
        </div>
      )}
      {incomplete && <DialogInformation isOpen={incomplete} handleClose={handleIncompleteClose} handleOK={handleIncompleteClose} title={incompleteTitle} body={incompleteBody} />}
      {confirmComplete && (
        <Confirmation
          isOpen={confirmComplete}
          handleClose={handleCompleteClose}
          handleConfirm={handleCompleteConfirm}
          title={`Complete Inspection QC${detailString}`}
          body={`Are you sure you would like to complete the Inspection QC${detailString}?`}
        ></Confirmation>
      )}
      {confirmMarketPlus && (
        <Confirmation
          isOpen={true}
          handleClose={handleMarketPlusClose}
          handleConfirm={handleMarketPlusConfirm}
          title={`Inspect Another Market`}
          body={`Are you sure you would like to move to another market for inspection?`}
        ></Confirmation>
      )}
      {notifyView && (
        <Confirmation isOpen={true} handleClose={handleNotifyClose} handleConfirm={() => {}} title={"Creating Notification"} body={undefined}>
          <InspectionNotify reloadData={() => {}} onClose={handleNotifyClose} onSubmit={handleNotifyConfirm} locked={true} inspectionNumber={inspectionID} />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(InspectionUnstyled);

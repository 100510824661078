import { client, apiHeaders, request } from "./_base";
import { config } from "../../config";

const endpoint = "producerph";

export type ProducerPHType = {
  id?: number;
  producer_id: number;
  validfrom: Date;
  validto: Date;
  producerphtype_id: number;
  created_at?: Date;
  updated_at?: Date;
};

export const producerph = client(endpoint, (item) => ({
  ...item,
}));

export type GetProducerPHFullType = {
  id?: number;
  producer_id: number;
  validfrom: Date;
  validto: Date;
  producerphtype_id: number;
  created_at?: Date;
  updated_at?: Date;
  producer_code: string;
  producer_name: string;
  producerphtype_detail: string;
};
export const getProducerPHFull = (producerid?: number) =>
  request(`${endpoint}/ext/full/${producerid ? producerid : ""}`, {
    headers: apiHeaders(),
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });

export type GetProducerPHFullDetailType = {
  id?: number;
  producer_id: number;
  producer_code: string;
  producer_name: string;
  validfrom: Date;
  validto: Date;
  producerphtype_id: number;
  producerphtype_detail: string;
  commoditycode: string;
  exportmarket: string;
  mrl: string;
  activeingredient: string;
  product: string;
  waterapplication100: string;
  waxapplication100: string;
  comments: string;
};
export const getProducerPHFullDetail = (producerid?: number) =>
  request(`${endpoint}/ext/fulldetail/${producerid ? producerid : "0"}`, {
    headers: apiHeaders(),
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });

import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

export const palletspecs = client("palletspec", (item) => ({
  ...item,
}));

const endpoint = "palletspec";

export type palletspectype = {
  id: number;
  orgid: number;
  code: number | string;
  carton: string;
  weight: number;
};

export const palletspecFull = () =>
  request(`${endpoint}/extend/all`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const palletspecCartons = () =>
  request(`${endpoint}/extend/cartons`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

export const PalletSpecsByVarietyId = (varietyId) =>
  request(`${endpoint}/PalletSpecsByVarietyId/${varietyId}`, {
    headers: apiHeaders(),
  }).then((result) => result.json());

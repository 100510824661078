import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";

import moment from "moment";

import { genericApi } from "../lib/genericAPI";
import { labtesttype } from "../lib/storage/labtest";

import { LabTestFullType } from "../lib/api/labtest";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
    },
    tableheader: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "black",
    },
    tableCol: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "black",
    },
    tableColDate: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "black",
      width: "100px",
    },
  });

type InspectionLabTestProps = {
  producer_id: number;
  puc_id: number;
  orchard_id: number;
  variety_id: number;
  handleAction: (value: number) => void;
} & WithStyles<typeof styles>;

const InspectionLabTestUnstyled: React.SFC<InspectionLabTestProps> = (props) => {
  const { classes, producer_id, puc_id, orchard_id, variety_id, handleAction } = props;
  const [labtestd, setLabtestd] = React.useState([]);

  useEffect(() => {
    const labtestGeneric = genericApi<labtesttype>("labtest");

    labtestGeneric.all().then((labtestLocal) => {
      const resultlabtest = labtestLocal
        .filter((item) => item.producer_id == producer_id)
        .filter((item) => item.puc_id == puc_id)
        .filter((item) => item.orchard_id == orchard_id)
        .filter((item) => item.variety_id == variety_id)
        .sort((a, b) => {
          if (a.sampledateend > b.sampledateend) return 1;
          if (a.sampledateend < b.sampledateend) return -1;
          return 1;
        });
      if (resultlabtest && resultlabtest.length > 0) {
        setLabtestd(resultlabtest);
      }
    });
  }, [producer_id, puc_id, orchard_id, variety_id]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Button
          key={`close`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "red" }}
          onClick={() => {
            handleAction(0);
          }}
        >
          <IconClose />
        </Button>
        <Button
          key={`check`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "green" }}
          onClick={() => {
            handleAction(1);
          }}
        >
          <IconCheck />
        </Button>
        <Button
          key={`na`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "orange" }}
          onClick={() => {
            handleAction(2);
          }}
        >
          {`N/A`}
        </Button>
      </AppBar>
      <table>
        <tbody>
          <tr key={`dethead`}>
            <td className={classes.tableheader}>{`DATE`}</td>
            <td className={classes.tableheader}>{`LAB`}</td>
            <td className={classes.tableheader}>{`TYPE`}</td>
            <td className={classes.tableheader}>{`#`}</td>
          </tr>
          {labtestd.map((item: LabTestFullType) => {
            return (
              <tr key={`det${item.id}`}>
                <td className={classes.tableColDate}>{`${moment(item.sampledate).format("DD/MM/YYYY")} ${moment(item.sampledateend).format("DD/MM/YYYY")}`}</td>
                <td className={classes.tableCol}>{`${item.lab}`}</td>
                <td className={classes.tableCol}>{`${item.labtesttype_detail}`}</td>
                <td className={classes.tableCol}>{item.approved == 1 ? <IconCheck style={{ color: "green" }} /> : <IconClose style={{ color: "red" }} />}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(styles)(InspectionLabTestUnstyled);

import { client, request } from "./_base";

export const inspectionactions = client("inspectionactions", item => ({
  ...item,
}));

export const actionsFindByInspectionId = id => {
  return request(`inspectionactions/ext/findByInspectionId/${id}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => {
      return data;
    });
};

import { client, request } from "./_base";

// users api exports
export const notify = client("notify", (item) => ({
  ...item,
}));

const endpoint = "notify";

export const notifyFull = () => {
  return request(`${endpoint}/ext/full`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const notifyFullByIdStatus = (toUserid, fromUserId, status) => {
  return request(`${endpoint}/ext/full/${toUserid}/${fromUserId}/${status}`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const notifyGetUserBadgeCount = (id) => {
  return request(`${endpoint}/ext/count/${id}`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

import React from "react";
import { useEffect, useState } from "react";
import history from "./lib/history";
import { Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { whoami } from "./lib/api/_base";

import Login from "./login";
import Dashboard from "./layout/dashboard";
import Home from "./home/home";
import Inspection from "./inspection/inspection";
import Sync from "./sync/syncmaster";
import Inspections from "./inspections/inspections";
import Notifications from "./notifications/notifications";
import PackInstructions from "./packinstructions/index";

import ErrorBoundary from "./lib/components/ErrorBoundary";

import { SnackbarProvider } from "notistack";
import { WSSProvider, WSSContext } from "./lib/context/WSSContext";
import { UserProvider } from "./lib/context/UserContext";
import { HydrateUser, HydrateApp } from "./lib/context/Hydrate";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
    },
  });

export type AppProps = {} & WithStyles<typeof styles>;

const LoginGaurd: React.SFC<any> = ({ match }) => {
  useEffect(() => {
    if (navigator.onLine) {
      whoami()
        .then((user) => {
          if (!user.username) {
            throw new Error("Not logged in");
          }
          localStorage.setItem("loggedIn", "true");
        })
        .catch((e) => {
          localStorage.setItem("loggedIn", "false");
          history.push("/login");
        });
    }
  }, [history.location.pathname]);
  return null;
};

const AppUnstyled: React.SFC<AppProps> = ({ children, classes }) => {
  useEffect(() => {
    window.addEventListener("message", (event) => {
      console.log("event.origin", event);
    });
    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router history={history}>
        <>
          <Route component={LoginGaurd} />
          <SnackbarProvider preventDuplicate>
            <UserProvider>
              <Route component={HydrateUser} />
              <Switch>
                <Route path="/login" component={Login} />
                <WSSProvider>
                  <Route component={HydrateApp} />
                  <Route
                    render={() => (
                      <Dashboard>
                        <Switch>
                          <Route exact path="/login" component={Login} />
                          <Route path="/" exact component={Home} />
                          <Route path="/home" component={Home} />
                          <Route path="/inspection/:id/:groupnum" component={Inspection} />
                          <Route path="/inspection/:id" component={Inspection} />
                          <Route path="/inspections" component={Inspections} />
                          <Route path="/syncmaster" component={Sync} />
                          <Route path="/notifications" component={Notifications} />
                          <Route path="/packinstructions" component={PackInstructions} />
                        </Switch>
                      </Dashboard>
                    )}
                  />
                </WSSProvider>
              </Switch>
            </UserProvider>
          </SnackbarProvider>
        </>
      </Router>
    </div>

    // <UserProvider>
    //   <Route component={HydrateUser} />
    //   <WSSProvider>
    //     <Router history={history}>
    //       <>
    //         <Route component={LoginGaurd} />
    //         <Switch>
    //           {/* <Route component={HydrateApp} /> */}
    //           <Route
    //             render={() => (
    //               <ErrorBoundary>
    //                 <Dashboard>
    //                   <Switch>

    //                   </Switch>
    //                 </Dashboard>
    //               </ErrorBoundary>
    //             )}
    //           />
    //         </Switch>
    //       </>
    //     </Router>
    //   </WSSProvider>
    // </UserProvider>
    // </div>
  );
};
const App = withStyles(styles)(AppUnstyled);
export default App;

import { genericApi } from "../genericAPI";

export type inspectiontype = {
  id: string;
  inspectiondate: Number;
  producer: string;
  puc: Number;
  orchard: Number;
  specialmarket: Number;
  labtest: Number;
  degreen: Number;
  pickref: string;
  brix: Number;
  acid: Number;
  packedmarket: string;
  packedcountry: string;
  postharvesttreatment: string;
  postharvestlabel: string;
  labelconsignee: Number;
  labelcountry: Number;
  piorder: Number;
  inspectionOrders: [
    {
      piinstruction_id: Number;
      labelcarton: Number;
      inventory: Number;
      weight: Number;
      firmness: Number;
      buttons: Number;
      grading: Number;
      shape: Number;
      sizing: Number;
      color: Number;
      blemish: Number;
      skintexture: Number;
      granulation: Number;
      general: Number;
      proposedmarket: string;
      proposedcountry: string;
      fruitspec: Number;
    },
  ];
  actions: Array<{ field: string; piinstruction_id: string; comments: string; images: any }>;
  processed: Number;
  username: string;
  facility: Number;
  juice: Number;
  variety_id: number;
  week_id: number;
  groupnumber: string;
  editReference: string;
  piorder_ordernum: string;
  flag: string;
};

export const newInspectionType: inspectiontype = {
  id: undefined,
  inspectiondate: Date.now(),
  producer: undefined,
  puc: undefined,
  orchard: undefined,
  specialmarket: undefined,
  labtest: undefined,
  degreen: undefined,
  pickref: undefined,
  brix: 0,
  acid: 0,
  packedmarket: undefined,
  packedcountry: undefined,
  postharvestlabel: undefined,
  postharvesttreatment: undefined,
  labelconsignee: undefined,
  labelcountry: undefined,
  piorder: undefined,
  inspectionOrders: undefined,
  actions: undefined,
  processed: 0,
  username: undefined,
  facility: undefined,
  juice: 0,
  variety_id: undefined,
  week_id: undefined,
  groupnumber: undefined,
  editReference: undefined,
  piorder_ordernum: undefined,
  flag: undefined,
};

export const inspectionsGetAll = async () => {
  const inspectionsGeneric = genericApi<inspectiontype>("inspections");
  const inspectionsLocal = await inspectionsGeneric.all();
  return inspectionsLocal;
};

export const inspectionsCreate = async ({
  id,
  inspectiondate,
  producer,
  puc,
  orchard,
  specialmarket,
  pickref,
  brix,
  acid,
  postharvesttreatment,
  postharvestlabel,
  labelconsignee,
  labelcountry,
  piorder,
  inspectionOrders,
  actions,
  processed,
  packedmarket,
  username,
  facility,
  juice,
  packedcountry,
  variety_id,
  week_id,
  groupnumber,
  editReference,
  piorder_ordernum,
  labtest,
  degreen,
  flag,
}) => {
  const inspectionsGeneric = genericApi<inspectiontype>("inspections");
  inspectionsGeneric.create({
    id,
    inspectiondate,
    producer,
    puc,
    orchard,
    specialmarket,
    pickref,
    brix,
    acid,
    postharvesttreatment,
    postharvestlabel,
    labelconsignee,
    labelcountry,
    piorder,
    inspectionOrders,
    actions,
    processed,
    packedmarket,
    username,
    facility,
    juice,
    packedcountry,
    variety_id,
    week_id,
    groupnumber,
    editReference,
    piorder_ordernum,
    labtest,
    degreen,
    flag,
  });
};

export const inspectionsRemove = async ({ id }) => {
  const inspectionsGeneric = genericApi<inspectiontype>("inspections");
  inspectionsGeneric.remove(id);
};

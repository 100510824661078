import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";
import IconFlare from "@material-ui/icons/Flare";
// import IconGesture from "@material-ui/icons/Gesture";
import Fab from "@material-ui/core/Fab";

import InspectionAction from "../../inspection/inspectionaction";

import { genericApi } from "../genericAPI";
import { inspectiontype } from "../storage/inspection";

export const GradeByNumbers = ({ inspectionid, field, piinstruction_id, value, handleGradeChange }) => {
  const [grade, setGrade] = React.useState("");
  const [actionOpen, setActionOpen] = React.useState(false);
  const [actionExist, setActionExist] = React.useState(false);

  useEffect(() => {
    setGrade(value);
    handleInitActionExist();
  }, [piinstruction_id, value]);

  const handleInitActionExist = () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      if (data) {
        if (data.actions) {
          const actionexistalready = data.actions.find((item) => item.field == field && item.piinstruction_id == piinstruction_id);
          if (actionexistalready) {
            setActionExist(true);
          } else {
            setActionExist(false);
          }
        }
      }
    });
  };

  const handleChange = (value: string) => {
    setGrade(value);
    handleGradeChange(value);
  };

  const handleAction = () => {
    setActionOpen(true);
  };

  const handleActionClose = () => {
    setActionOpen(false);
  };

  const handleConfirm = (action: any) => {
    setActionOpen(false);
    setActionExist(true);
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      const actionsArr: Array<{ field: string; piinstruction_id: string; comments: string; images: any }> = [];
      actionsArr.push({ ...action, piinstruction_id: piinstruction_id });

      if (data.actions) {
        data.actions.map((item) => {
          if (item.field == field && item.piinstruction_id == piinstruction_id) {
          } else {
            actionsArr.push({ field: item.field, piinstruction_id: item.piinstruction_id, comments: item.comments, images: item.images });
          }
        });
      }

      inspectionsGeneric.update(inspectionid, { ...data, actions: actionsArr }).then(() => {});
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {actionOpen && (
        <InspectionAction
          key={`${piinstruction_id}${field}actiond`}
          inspectionid={inspectionid}
          field={field}
          piinstruction_id={piinstruction_id}
          handleClose={handleActionClose}
          handleConfirm={handleConfirm}
        />
      )}
      <Button
        key={`${piinstruction_id}${field}1`}
        variant="contained"
        size="small"
        color="primary"
        style={{ minWidth: "40px", margin: "2.5px", backgroundColor: grade == "1" ? "red" : "gray", color: "black" }}
        onClick={() => {
          handleChange("1");
        }}
      >
        1
      </Button>
      <Button
        key={`${piinstruction_id}${field}2`}
        variant="contained"
        size="small"
        color="primary"
        style={{ minWidth: "40px", margin: "2.5px", backgroundColor: grade == "2" ? "orange" : "gray", color: "black" }}
        onClick={() => {
          handleChange("2");
        }}
      >
        2
      </Button>
      <Button
        key={`${piinstruction_id}${field}3`}
        variant="contained"
        size="small"
        color="primary"
        style={{ minWidth: "40px", margin: "2.5px", backgroundColor: grade == "3" ? "green" : "gray", color: "black" }}
        onClick={() => {
          handleChange("3");
        }}
      >
        3
      </Button>
      {actionExist && (
        <Fab key={`${piinstruction_id}${field}action`} color="primary" aria-label="Add" size={"small"} style={{ float: "right", backgroundColor: "orange" }} onClick={handleAction}>
          <IconFlare />
        </Fab>
      )}
      {!actionExist && (
        <Fab key={`${piinstruction_id}${field}action`} color="primary" aria-label="Add" size={"small"} style={{ float: "right" }} onClick={handleAction}>
          <IconFlare />
        </Fab>
      )}
    </div>
  );
};

export const GradeByBit: React.FC<any> = ({ children, inspectionid, field, piinstruction_id, value, handleGradeChange, required = true, showCameraButton = true }) => {
  const [grade, setGrade] = React.useState(value);
  const [actionOpen, setActionOpen] = React.useState(false);
  const [actionExist, setActionExist] = React.useState(false);

  useEffect(() => {
    setGrade(value);
    handleInitActionExist();
  }, [piinstruction_id, value, required]);

  const handleInitActionExist = () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      if (data) {
        if (data.actions) {
          const actionexist = data.actions.find((item) => item.field == field && item.piinstruction_id == piinstruction_id);
          if (actionexist) {
            setActionExist(true);
          } else {
            setActionExist(false);
          }
        }
      }
    });
  };

  const handleChange = (value: string) => {
    setGrade(value);
    handleGradeChange(value);
  };

  const handleAction = () => {
    setActionOpen(true);
  };

  const handleActionClose = () => {
    setActionOpen(false);
  };

  const handleConfirm = (action: any) => {
    setActionOpen(false);
    setActionExist(true);
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      const actionsArr: Array<{ field: string; piinstruction_id: string; comments: string; images: any }> = [];
      actionsArr.push({ ...action, piinstruction_id: piinstruction_id });

      if (data.actions) {
        data.actions.map((item) => {
          if (item.field == field && item.piinstruction_id == piinstruction_id) {
          } else {
            actionsArr.push({ field: item.field, piinstruction_id: item.piinstruction_id, comments: item.comments, images: item.images });
          }
        });
      }

      inspectionsGeneric.update(inspectionid, { ...data, actions: actionsArr }).then(() => {});
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
      <div>
        {actionOpen && (
          <InspectionAction
            key={`${piinstruction_id}${field}actiond`}
            inspectionid={inspectionid}
            field={field}
            piinstruction_id={piinstruction_id}
            handleClose={handleActionClose}
            handleConfirm={handleConfirm}
          />
        )}
        <Button
          key={`${piinstruction_id}${field}0`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "2.5px", backgroundColor: grade == "0" ? "red" : "gray", color: "black" }}
          onClick={() => {
            handleChange("0");
          }}
        >
          <IconClose />
        </Button>
        <Button
          key={`${piinstruction_id}${field}1`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "2.5px", backgroundColor: grade == "1" ? "green" : "gray", color: "black" }}
          onClick={() => {
            handleChange("1");
          }}
        >
          <IconCheck />
        </Button>
        {!required && (
          <Button
            key={`${piinstruction_id}${field}2`}
            variant="contained"
            size="small"
            color="primary"
            style={{ minWidth: "40px", margin: "2.5px", backgroundColor: grade == "2" ? "orange" : "gray", color: "black" }}
            onClick={() => {
              handleChange("2");
            }}
          >
            {`N/A`}
          </Button>
        )}
      </div>
      <div style={{ float: "right" }}>
        {children}
        {showCameraButton && (
          <Fab
            key={`${piinstruction_id}${field}action`}
            color="primary"
            aria-label="Add"
            size={"small"}
            style={{ float: "right", backgroundColor: actionExist ? "orange" : "" }}
            onClick={handleAction}
          >
            <IconFlare />
          </Fab>
        )}
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";

import { genericApi } from "../lib/genericAPI";
import { piinstructiontype } from "../lib/storage/piinstructions";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing.unit,
  },
  summary: {
  },
});

type InspectionPHLabelProps = {
  piorderid: string,
  packedmarket: string,
  packedcountry: string,
  handleClose: () => void,
  handleConfirm: () => void,
} & WithStyles<typeof styles>;

const InspectionPHLabelUnstyled: React.SFC<InspectionPHLabelProps> = (props) => {
  const { classes, piorderid, packedmarket, packedcountry, handleClose, handleConfirm } = props;
  const [facility, setFacility] = React.useState("");

  useEffect(() => {
    const piinstructionsGeneric = genericApi<piinstructiontype>("piinstructions");
    piinstructionsGeneric.all().then(piinstructionsLocal => {
      const resultpiinstructions = piinstructionsLocal
      .filter(item => item.orgid == piorderid)
      .filter(item => item.target_market == packedmarket)
      .filter(item => item.target_country == packedcountry)
      .sort((a, b) => {
        if (a.groupline > b.groupline) return 1;
        if (a.groupline < b.groupline) return -1;
        return 1;
      });
      if (resultpiinstructions && resultpiinstructions.length > 0) {
        setFacility(resultpiinstructions[0].coldroom)
      }
    });
  }, [classes]);

  return (
    <div className={classes.root}>
      <AppBar position="static">        
        <Button
          key={`close`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "red" }}
          onClick={() => { handleClose() }}
        >
          <IconClose />
        </Button>
        <Button
          key={`check`}
          variant="contained"
          size="small"
          color="primary"
          style={{ minWidth: "40px", margin: "5px", backgroundColor: "green" }}
          onClick={() => { handleConfirm() }}
        >
          <IconCheck />
        </Button>
      </AppBar>
      <Typography
        variant="h2"
        gutterBottom
      >
        {facility}
      </Typography>
    </div>

  )
}

export default withStyles(styles)(InspectionPHLabelUnstyled);

import React, { useEffect, Fragment } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import history from "../lib/history";

import { genericApi } from "../lib/genericAPI";
import { inspectiontype } from "../lib/storage/inspection";
import { producertype } from "../lib/storage/producers";
import { piordergroupedtype } from "../lib/storage/piordergrouped";

import { inspections, findByNumber } from "../lib/api/inspection";

import moment from "moment";
import LZString from "lz-string";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit
    },
    button: {
      margin: theme.spacing.unit / 2
    }
  });

type InspectionsLocalProps = {
  status: Number;
} & WithStyles<typeof styles>;

const InspectionsLocalUnstyled: React.SFC<InspectionsLocalProps> = props => {
  const { classes, status } = props;

  const [inspectionsData, setInspectionsData] = React.useState([]);
  const [producers, setProducers] = React.useState([]);
  const [piorders, setPIorders] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [selectedInspectionid, setSelectedInspectionid] = React.useState();

  useEffect(() => {
    loadData();
    if (status != 2) {
      setInterval(() => {
        loadData();
      }, 5000);
    }
  }, [status]);

  const loadData = async () => {
    setMessage("");
    setSelectedInspectionid(undefined);
    loadPIOrders();
    loadInspections();
  };

  const loadPIOrders = () => {
    const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
    piordergroupedGeneric.all().then(allData => {
      const sortedData = allData.sort((a, b) => {
        if (a && b) {
          if (a.ordernum > b.ordernum) return -1;
          if (a.ordernum < b.ordernum) return 1;
        }
        return -1;
      });
      setPIorders(sortedData);
    });
  };

  const loadInspections = () => {
    const producersGeneric = genericApi<producertype>("producers");
    producersGeneric.all().then(producersAll => {
      setProducers(producersAll);
      const inspectionsGeneric = genericApi<inspectiontype>("inspections");
      inspectionsGeneric.all().then(data => {
        const newData = data.map(inspection => {
          const prodexist = producersAll.find(prod => prod.orgid == inspection.producer);
          if (prodexist) {
            inspection.producer = prodexist.orgid;
          }
          return inspection;
        });
        setInspectionsData(newData);
      });
    });
  };

  const handleInspectionClick = async id => {
    if (status == 0) {
      history.push(`/inspection/${id}`);
    } else if (status == 1 && !navigator.onLine) {
      resetInspection(id);
      history.push(`/inspection/${id}`);
    } else if (status == 2) {
      const resultinsp = inspectionsData.find(inspection => inspection.id == id);

      resultinsp.actions.map(async action => {
        if (action.images && action.images.length > 0) {
          for (let index = 0; index < action.images.length; index++) {
            const element = action.images[index];
            if (!element.includes("base64")) {
              action.images[index] = await LZString.decompress(element);
            }
          }
        }
      });

      setMessage(`${JSON.stringify(resultinsp)}`);
      setSelectedInspectionid(id);
    }
    return;
  };

  const getPIOrderNumber = piorderid => {
    const result = piorders.find(pio => pio.orgid == piorderid);
    if (result) {
      return result.ordernum;
    }
    return undefined;
  };

  const resetInspection = id => {
    const resultinsp = inspectionsData.find(inspection => inspection.id == id);
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.update(resultinsp.id, { ...resultinsp, processed: 0 });

    findByNumber(id).then(datasql => {
      if (datasql && datasql.length > 0) {
        inspections.remove(datasql[0].id);
      }
    });
    loadData();
  };

  const download = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  // Start file download.
  const handleDownload = () => {
    var text = message;
    var filename = `log_${selectedInspectionid}.txt`;

    download(filename, text);
  };

  return (
    <div className={classes.root}>
      {inspectionsData &&
        inspectionsData.map(item => {
          if (item.producer && item.pickref && item.processed == status) {
            const ordernumber = getPIOrderNumber(item.piorder);
            return (
              <Button
                key={`button${item.id}`}
                variant="contained"
                color={status == 0 ? "primary" : "secondary"}
                onClick={() => {
                  handleInspectionClick(item.id);
                }}
                style={{ backgroundColor: item.processed && item.processed == 1 ? "green" : item.processed == 2 ? "orange" : "primary" }}
                className={classes.button}
              >
                {/* {`${item.pickref} (${item.producer})`} */}
                {`${ordernumber}${item.packedmarket} (${item.pickref}) ${item.username} - ${moment(Number(item.inspectiondate)).format("DD MMM")}`}
              </Button>
            );
          }
        })}
      {status == 2 && message.length > 0 && (
        <div>
          {message.length > 0 && (
            <div>
              <Button
                key={`buttonreset`}
                variant="contained"
                onClick={() => {
                  resetInspection(selectedInspectionid);
                }}
                style={{ backgroundColor: "red" }}
                className={classes.button}
              >
                {/* {`${item.pickref} (${item.producer})`} */}
                reset inspection
              </Button>
              <Button
                id={`dwn-btn`}
                key={`buttondownload`}
                variant="contained"
                color={"primary"}
                onClick={() => {
                  handleDownload();
                }}
                className={classes.button}
              >
                {/* {`${item.pickref} (${item.producer})`} */}
                download
              </Button>
            </div>
          )}
          {/* <TextField
            multiline={true}
            style={{ width: "100%" }} 
            value={message}
          /> */}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(InspectionsLocalUnstyled);

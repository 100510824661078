import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import moment from "moment";

import { ComboSelection } from "../lib/components/ComboSelection";
import { genericApi } from "../lib/genericAPI";
import { producertype } from "../lib/storage/producers";

import { findBy } from "../lib/api/inspection";

import InspectionsFull from "./inspectionsfull";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
    },
  });

type InspectionsProps = {} & WithStyles<typeof styles>;

const InspectionsUnstyled: React.SFC<InspectionsProps> = (props) => {
  const { classes } = props;
  const [openInspectionFull, setOpenInspectionFull] = React.useState(false);
  const [producersData, setProducersData] = React.useState([]);
  const [selectedInspection, setSelectedInspection] = React.useState({ id: 0 });
  const [inspectionData, setInspectionData] = React.useState([]);
  const [inspectionDataOrg, setInspectionDataOrg] = React.useState([]);
  const [pickrefData, setPickrefData] = React.useState([]);
  const [weekData, setWeekData] = React.useState([]);
  const [state, setState] = React.useState({
    producer: undefined,
    pickref: undefined,
    week: undefined,
  });

  useEffect(() => {
    loadData();
  }, [classes]);

  const loadData = async () => {
    const producersGeneric = genericApi<producertype>("producers");
    producersGeneric.all().then((producersLocal) => {
      const resultProducers = producersLocal.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      });
      setProducersData(resultProducers);
    });
    findBy(0, 0, 10).then((data) => {
      setInspectionData(data.filter((item) => item.status != 1 && item.status != 3));
    });
  };

  const loadDataInspections = async (producerid) => {
    findBy(0, producerid.length == 0 ? 0 : producerid).then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.created_at > b.created_at) return -1;
        if (a.created_at < b.created_at) return 1;
        return -1;
      });
      setInspectionData(dataSorted);
      setInspectionDataOrg(dataSorted);

      const pickrefsArr = [];
      dataSorted.map((ins) => {
        if (pickrefsArr.indexOf(ins.pickref) == -1) {
          pickrefsArr.push(ins.pickref);
        }
      });
      setPickrefData(pickrefsArr);

      const weeksArr = [];
      dataSorted.map((ins) => {
        if (weeksArr.indexOf(ins.piorder_ordernum.toString().substring(0, 4)) == -1) {
          weeksArr.push(ins.piorder_ordernum.toString().substring(0, 4));
        }
      });
      setWeekData(weeksArr);
    });
  };

  const onSelectionChange = (name) => (value) => {
    let newState = { ...state, [name]: value };
    if (name == "producer") {
      loadDataInspections(value);
    }
    if (name == "pickref" || name == "week") {
      handleFilter(newState.pickref, newState.week);
    }

    setState(newState);
  };

  const handleFilter = (pickref, week) => {
    const inspectionDataFilteredPick = [];
    inspectionDataOrg.map((ins) => {
      if (pickref && pickref.length > 0) {
        if (ins.pickref == pickref) {
          inspectionDataFilteredPick.push(ins);
        }
      } else {
        inspectionDataFilteredPick.push(ins);
      }
    });

    const inspectionDataFilteredWeek = [];
    inspectionDataFilteredPick.map((ins) => {
      if (week && week.length > 0) {
        if (ins.piorder_ordernum.toString().substring(0, 4) == week) {
          inspectionDataFilteredWeek.push(ins);
        }
      } else {
        inspectionDataFilteredWeek.push(ins);
      }
    });

    setInspectionData(inspectionDataFilteredWeek);
  };

  const handleSelectInspection = (inspection) => {
    setSelectedInspection(inspection);
    handleOpenInspectionFull();
  };

  const handleOpenInspectionFull = () => {
    setOpenInspectionFull(true);
  };

  const handleCloseInspectionFull = () => {
    setOpenInspectionFull(false);
    handleSelectInspection(undefined);
  };

  return (
    <div className={classes.root}>
      {!navigator.onLine && <div>System is not currently online</div>}
      {navigator.onLine && (
        <div>
          <ComboSelection
            classes={classes}
            name="producer"
            selected={state.producer}
            setSelected={onSelectionChange("producer")}
            data={producersData.map((item) => {
              return { id: item.id, code: item.orgid, name: item.name };
            })}
          />
          <ComboSelection
            classes={classes}
            name="week"
            selected={state.week}
            setSelected={onSelectionChange("week")}
            data={weekData.map((item) => {
              return { id: item, code: item, name: item };
            })}
          />
          <ComboSelection
            classes={classes}
            name="pickref"
            selected={state.pickref}
            setSelected={onSelectionChange("pickref")}
            data={pickrefData.map((item) => {
              return { id: item, code: item, name: item };
            })}
          />
          {inspectionData && inspectionData.length > 0 && (
            <div>
              <List dense>
                {inspectionData.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value.id}`;
                  return (
                    <ListItem
                      key={value.id}
                      button
                      onClick={() => {
                        handleSelectInspection(value);
                      }}
                      style={{ backgroundColor: selectedInspection && value.id == selectedInspection.id ? "lightgreen" : "white" }}
                    >
                      <ListItemText
                        id={labelId}
                        primary={`${value.piorder_ordernum}${value.packedmarket} (${value.pickref}) - ${value.username} - ${moment(Number(value.inspectiondate)).format(
                          "DD MMM YYYY",
                        )}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          )}
          {selectedInspection && <InspectionsFull isOpen={openInspectionFull} handleClose={handleCloseInspectionFull} inspection={selectedInspection} />}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(InspectionsUnstyled);

import React, { useEffect } from "react";
import isEmpty from "lodash";
import history from "../lib/history";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import IconClose from "@material-ui/icons/Close";
import IconEdit from "@material-ui/icons/Edit";
import IconCheck from "@material-ui/icons/Check";
import IconBlock from "@material-ui/icons/Block";

import moment from "moment";

import { ordersFindByInspectionId } from "../lib/api/inspectionorders";
import { actionsFindByInspectionId } from "../lib/api/inspectionactions";
import { inspections } from "../lib/api/inspection";
import { piorder } from "../lib/api/piorder";
import { genericApi } from "../lib/genericAPI";
import { inspectiontype } from "../lib/storage/inspection";

import LZString from "lz-string";
import { whoami } from "../lib/api/_base";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
    },
    appBar: {
      position: "relative",
    },
    title: {
      color: "white",
      marginLeft: theme.spacing.unit * 2,
    },
    table: {
      margin: theme.spacing.unit * 2,
    },
    edit: {
      margin: theme.spacing.unit * 2,
    },
  });

type InspectionsFullProps = {
  isOpen: boolean;
  handleClose: () => void;
  inspection: any;
} & WithStyles<typeof styles>;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const InspectionsFullUnstyled: React.SFC<InspectionsFullProps> = (props) => {
  const { isOpen, handleClose, inspection, classes } = props;

  const [orders, setOrders] = React.useState([]);
  const [actions, setActions] = React.useState([]);
  const [imagesSelected, setImagesSelected] = React.useState([]);
  const [order, setOrder] = React.useState([]);

  useEffect(() => {
    const loadData = async () => {
      await loadInspectionOrders();
      await loadInspectionActions();
      await loadOrder();
    };
    loadData();
  }, [inspection]);

  const loadInspectionOrders = () => {
    ordersFindByInspectionId(inspection.id).then((data) => {
      setOrders(data);
    });
  };

  const loadInspectionActions = () => {
    actionsFindByInspectionId(inspection.id).then((data) => {
      setActions(data);
    });
  };
  const loadOrder = () => {
    if (inspection.piorder) {
      piorder.single(inspection.piorder).then((data) => {
        setOrder(data);
      });
    }
  };

  const handleEdit = async () => {
    const inspectionOrder = [
      {
        piinstruction_id: orders[0].piinstruction_id,
        labelcarton: `${orders[0].labelcarton}`,
        inventory: `${orders[0].inventory}`,
        weight: `${orders[0].weight}`,
        firmness: `${orders[0].firmness}`,
        buttons: `${orders[0].buttons}`,
        grading: `${orders[0].grading}`,
        shape: `${orders[0].shape}`,
        sizing: `${orders[0].sizing}`,
        color: `${orders[0].color}`,
        blemish: `${orders[0].blemish}`,
        actionexist: actions && actions.length > 0,
        skintexture: `${orders[0].skintexture}`,
        granulation: `${orders[0].granulation}`,
        general: `${orders[0].general}`,
        proposedmarket: `${orders[0].proposedmarket}`,
        proposedcountry: `${orders[0].proposedcountry}`,
        fruitspec: `${orders[0].fruitspec}`,
      },
    ];

    const newData = {
      ...inspection,
      inspectiondate: Number.parseInt(inspection.inspectiondate),
      processed: 0,
      producer: inspection.producer != undefined ? inspection.producer.toString() : inspection.producer,
      puc: inspection.puc != undefined ? inspection.puc.toString() : inspection.puc,
      orchard: inspection.orchard != undefined ? inspection.orchard.toString() : inspection.orchard,
      groupnumber: inspection.groupnumber ? inspection.groupnumber : inspection.number,
      id: inspection.id,
      packedcountry: inspection.packedcountry,
      packedmarket: inspection.packedmarket,
      brix: inspection.brix,
      acid: inspection.acid,
      facility: inspection.facility != undefined ? inspection.facility.toString() : inspection.facility,
      postharvestlabel: inspection.postharvestlabel != undefined ? inspection.postharvestlabel.toString() : inspection.postharvestlabel,
      postharvesttreatment: inspection.postharvesttreatment != undefined ? inspection.postharvesttreatment.toString() : inspection.postharvesttreatment,
      specialmarket: inspection.specialmarket != undefined ? inspection.specialmarket.toString() : inspection.specialmarket,
      labelconsignee: inspection.labelconsignee,
      labelcountry: inspection.labelcountry,
      week_id: Object.assign(order).week,
      variety_id: Object.assign(order).variety != undefined ? Object.assign(order).variety.toString() : Object.assign(order).variety,
      inspectionOrders: inspectionOrder,
      actions: actions,
    };

    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.create(newData).then(async (newId) => {
      newData.number = newId.toString();
      newData.id = newId;
      newData.editReference = inspection.id;
      actions.map((action, actionIndex) => {
        newData.actions[actionIndex] = action;
        newData.actions[actionIndex].images.map((image, imageIndex) => {
          const newImage = image.base64 ? { ...image, base64: LZString.compress(image.base64) } : { base64: LZString.compress(image), width: 5, height: 5 };
          newData.actions[actionIndex].images[imageIndex] = { ...newImage };
        });
      });

      new Promise(async (resolve, reject) => {
        const inspOrder = await ordersFindByInspectionId(inspection.id);
        const actions = await actionsFindByInspectionId(inspection.id);
        if (
          inspOrder.blemish != newData.blemish ||
          inspOrder.buttons != newData.buttons ||
          inspOrder.color != newData.color ||
          inspOrder.firmness != newData.firmness ||
          inspOrder.fruitspec != newData.fruitspec ||
          inspOrder.general != newData.general ||
          inspOrder.grading != newData.grading ||
          inspOrder.granulation != newData.granulation ||
          inspOrder.inventory != newData.inventory ||
          inspOrder.labelcarton != newData.labelcarton ||
          inspOrder.piinstruction_id != newData.piinstruction_id ||
          inspOrder.proposedcountry != newData.proposedcountry ||
          inspOrder.proposedmarket != newData.proposedmarket ||
          inspOrder.shape != newData.shape ||
          inspOrder.sizing != newData.sizing ||
          inspOrder.skintexture != newData.skintexture ||
          inspOrder.weight != newData.weight
        ) {
          reject("Cound not validate the converted inspections order.");
        } else if (actions.length != newData.actions.length) {
          reject("Could not validate the converted the inspections actions.");
        } else {
          resolve(null);
          inspectionsGeneric
            .update(newData.number, newData)
            .then(() => {
              localStorage.setItem("currentinspection", newData.number);
              history.push(`/inspection/${newData.number}`);
            })
            .then(async () => {
              const dataToUpdate = {
                data: {
                  status: 2,
                  editUser: await whoami().then((data) => {
                    return data.username;
                  }),
                },
              };

              inspections.update(inspection.id, dataToUpdate);
            });
        }
      }).catch((err) => {
        inspectionsGeneric.remove(newData.number);
        console.error("There was an error converting the inspection to editable: Error-->", err);
      });
    });
  };

  return (
    <div className={classes.root}>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <IconClose />
            </IconButton>
            {/* <IconButton color="inherit" onClick={handleShare} aria-label="Close">
              <IconShare />
            </IconButton> */}
            <Typography variant="h6" className={classes.title}>
              Inspection Summary
            </Typography>

            <IconButton color="inherit" onClick={handleEdit} className={classes.edit} aria-label="Edit">
              <IconEdit />
            </IconButton>
          </Toolbar>
        </AppBar>
        <table className={classes.table}>
          <tbody>
            {tableRow("Instruction: ", inspection.piorder_ordernum)}
            {tableRow("Producer: ", inspection.producer_name)}
            {tableRow("PUC: ", inspection.puc_code)}
            {tableRow("Orchard: ", inspection.orchard_code)}
            {tableRow("Pick Ref: ", inspection.pickref)}
            {tableRow("Special Market: ", inspection.specialmarket == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow("Packed Market: ", inspection.packedmarket)}
            {tableRow("Packed Country: ", inspection.packedcountry)}
            {tableRow("Lab Test: ", inspection.labtest == 0 ? <IconClose style={{ color: red }} /> : inspection.labtest == 1 ? <IconCheck style={{ color: green }} /> : "N/A")}
            {tableRow(
              "Internals: ",
              `${inspection.brix} : ${inspection.acid}  -  ${!isNaN(inspection.brix / inspection.acid) ? (inspection.brix / inspection.acid).toFixed(2) : 0}`,
            )}
            {tableRow("Juice: ", `${inspection.juice} %`)}
            {tableRow("Facility: ", inspection.facility == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow("Degreen: ", inspection.degreen == 0 ? <IconClose style={{ color: red }} /> : inspection.degreen == 1 ? <IconCheck style={{ color: green }} /> : "N/A")}
            {tableRow("PH Treatment: ", inspection.postharvesttreatment == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow("PH Labels: ", inspection.postharvestlabel == 0 ? <IconClose style={{ color: red }} /> : <IconCheck style={{ color: green }} />)}
            {tableRow(
              "Consignee Label: ",
              inspection.labelconsignee == 0 ? <IconClose style={{ color: red }} /> : inspection.labelconsignee == 1 ? <IconCheck style={{ color: green }} /> : "N/A",
            )}
            {tableRow(
              "Country Label: ",
              inspection.labelcountry == 0 ? <IconClose style={{ color: red }} /> : inspection.labelcountry == 1 ? <IconCheck style={{ color: green }} /> : "N/A",
            )}
            {tableRow("Inspection Date: ", moment(Number(inspection.inspectiondate)).format("DD-MMM-YYYY HH:mm"))}
            {tableRow("Completed Date: ", moment(inspection.created_at).format("DD-MMM-YYYY HH:mm"))}
          </tbody>
        </table>
        <div>
          {orders.map((order) => {
            return tableRowOrder(order, actions);
          })}
        </div>
        <div>
          {tableRowOrderActionsMain(inspection, actions)}
          {orders.map((order) => {
            return tableRowOrderActions(inspection, order, actions);
          })}
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(InspectionsFullUnstyled);

const tableRow = (label, value) => {
  // const cellWidth = (value == "NO" || value == "YES") ? "60px" : "50%";
  return (
    <tr>
      <td style={{ textAlign: "right", width: "150px" }}>
        <Typography>{label}</Typography>
      </td>
      <td style={{ textAlign: "left", paddingLeft: "5px" }}>{value}</td>
    </tr>
  );
};

const tableRowBit = (label, value, actions, piinstruction_id, field) => {
  const valueColor = value == 0 ? "red" : value == 1 ? "green" : "gray";
  return (
    <>
      <td style={{ width: "100px" }}>
        <Typography variant="subtitle2">{label}</Typography>
        <div style={{ textAlign: "center", width: "50px", backgroundColor: valueColor }}>{value == 0 ? <IconClose /> : value == 1 ? <IconCheck /> : <IconBlock />}</div>
      </td>
    </>
  );
};

const tableRowValue = (label, value, actions, piinstruction_id, field) => {
  const valueColor = value == 1 ? "red" : value == 2 ? "orange" : value == 3 ? "green" : "gray";
  return (
    <>
      <td key={`${label}${value}`} style={{ width: "60px" }}>
        <Typography variant="subtitle2">{label}</Typography>
        <div style={{ textAlign: "center", width: "50px", height: "30px", backgroundColor: valueColor }}>{value ? value : 0}</div>
      </td>
    </>
  );
};
const red = "#FF0000";
const green = "#00a700";

const tableRowActions = (action, detail = undefined, isBitField) => {
  if (action) {
    if (action.images.toString().length > 0 && action.images.constructor !== Array) {
      action.images = JSON.parse(action.images);
    }
    const valueColor = !detail ? undefined : detail[action.field] == 1 ? "red" : detail[action.field] == 2 ? "orange" : detail[action.field] == 3 ? "green" : "gray";
    return (
      <td>
        <table>
          <tbody>
            <tr>
              <td style={{ height: "100px" }}>
                <div style={{ float: "left", display: "inline" }}>
                  {isBitField && detail[action.field] == 0 && <IconClose style={{ marginBottom: "-7px", width: 60, height: 60, color: red }} />}
                  {isBitField && detail[action.field] == 1 && <IconCheck style={{ marginBottom: "-7px", width: 60, height: 60, color: green }} />}
                  {isBitField && detail[action.field] == 2 && (
                    <div style={{ verticalAlign: "middle", textAlign: "center", height: "50px", marginRight: "5px", width: "55px", backgroundColor: valueColor }}>
                      <div style={{ paddingTop: "15px" }}>N/A</div>
                    </div>
                  )}

                  {!isBitField && (
                    <div style={{ verticalAlign: "middle", textAlign: "center", height: "50px", marginRight: "5px", width: "55px", backgroundColor: valueColor }}>
                      <div style={{ paddingTop: "15px" }}>{detail[action.field]}</div>
                    </div>
                  )}
                </div>
                <div style={{ float: "left", display: "inline" }}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                    {action.field.toString().toUpperCase()}
                    <br />
                  </Typography>
                  <span style={{ fontSize: "12px" }}>Comment: {action.comments}</span>
                </div>
              </td>
            </tr>

            <tr key={`images${action.id}`} style={{ textAlign: "left", display: "inline" }}>
              <td colSpan={9}>
                {action.images &&
                  action.images.length > 0 &&
                  action.images.map((image, idx) => {
                    const imagebase = image.base64 ? image.base64 : image;
                    return (
                      <div key={`imagelist${idx}`} style={{ display: "inline", marginLeft: "5px" }}>
                        <img key={`${imagebase}image${idx}`} src={imagebase.includes("base64") ? imagebase : LZString.decompress(imagebase)} alt="captured" width="150px" />
                      </div>
                    );
                  })}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    );
  } else {
    return undefined;
  }
};

const returnOrderActionByField = (actions, piinstruction_id, field) => {
  const actionFind = actions.find((act) => act.field == (field ? field : act.field) && act.piinstruction_id == piinstruction_id);
  return actionFind;
};

const tableRowOrder = (order, actions) => {
  return (
    <Paper key={order.id} style={{ paddingLeft: "5px" }}>
      <Typography variant="button" gutterBottom>
        <div key={`${order.id}${actions.field}`}>
          {`${order.target_market} | ${order.target_country} | ${order.palletspec_code} | ${order.palletspec_carton} | ${order.count.split("/").join(" | ")} | ${
            order.inventory_description
          }`}
        </div>
      </Typography>
      <table key={`bits${order.id}`} style={{ paddingBottom: "10px" }}>
        <tbody>
          <tr>
            {tableRowBit("FRUIT SPEC", order.fruitspec, actions, order.piinstruction_id, "fruitspec")}
            {tableRowBit("INVENTORY", order.inventory, actions, order.piinstruction_id, "inventory")}
            {tableRowBit("WEIGHT", order.weight, actions, order.piinstruction_id, "weight")}
          </tr>
          <tr>
            {tableRowBit("CARTON LBL", order.labelcarton, actions, order.piinstruction_id, "labelcarton")}
            {tableRowBit("GENERAL", order.general, actions, order.piinstruction_id, "general")}
            {tableRowBit("FIRMNESS", order.firmness, actions, order.piinstruction_id, "firmness")}
          </tr>
          <tr>
            {tableRowBit("BUTTONS", order.buttons, actions, order.piinstruction_id, "buttons")}
            {tableRowValue("COLOR", order.color, actions, order.piinstruction_id, "color")}
            {tableRowValue("BLEMISH", order.blemish, actions, order.piinstruction_id, "blemish")}
          </tr>
          <tr>
            {tableRowValue("TEXTURE", order.skintexture, actions, order.piinstruction_id, "skintexture")}
            {tableRowValue("GRANULATION", order.granulation, actions, order.piinstruction_id, "granulation")}
            {tableRowBit("GRADING", order.grading, actions, order.piinstruction_id, "grading")}
          </tr>
          <tr>
            {tableRowBit("SHAPE", order.shape, actions, order.piinstruction_id, "shape")}
            {tableRowBit("SIZING", order.sizing, actions, order.piinstruction_id, "sizing")}
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <br />
              <Typography variant="subtitle2">{`Proposed Market : `}</Typography>
            </td>
            <td>
              <br />
              <Typography variant="subtitle2">{`${order.proposedmarket}`}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <Typography variant="subtitle2">{`Proposed Country : `}</Typography>
            </td>
            <td>
              <br />
              <Typography variant="subtitle2">{`${order.proposedcountry}`}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </Paper>
  );
};

const tableRowOrderActionsMain = (inspection, actions) => {
  return (
    <Paper key={`mainOrderActions`} style={{ paddingLeft: "5px" }}>
      {actions.map((action) => {
        if (action.piinstruction_id == 0) {
          return (
            <table key={`bits${action.id}`} style={{ paddingBottom: "10px" }}>
              <tbody>
                <tr>{tableRowActions(action, inspection, true)}</tr>
              </tbody>
            </table>
          );
        }
      })}
    </Paper>
  );
};

const tableRowOrderActions = (inspection, order, actions) => {
  return (
    <Paper key={order.id} style={{ paddingLeft: "5px" }}>
      {returnOrderActionByField(actions, order.piinstruction_id, undefined) && (
        <>
          <Typography variant="body2" gutterBottom>
            {/* <div key={order.id}> */}
            {actions.length > 0 &&
              `${order.target_market} | ${order.target_country} | ${order.palletspec_code} | ${order.palletspec_carton} | ${order.count.split("/").join(" | ")} | ${
                order.inventory_description
              }`}
            {/* </div> */}
          </Typography>

          <table key={`bits${order.id}`} style={{ paddingBottom: "10px" }}>
            <tbody>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "fruitspec"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "inventory"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "weight"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "labelcarton"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "general"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "firmness"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "buttons"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "color"), order, false)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "blemish"), order, false)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "skintexture"), order, false)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "granulation"), order, false)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "grading"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "shape"), order, true)}</tr>
              <tr>{tableRowActions(returnOrderActionByField(actions, order.piinstruction_id, "sizing"), order, true)}</tr>
            </tbody>
          </table>
        </>
      )}
    </Paper>
  );
};

import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

export const varieties = client("variety", item => ({
  ...item,
}));

export const varietyFull = () => {
  return request(`variety/extend/all`, {
    headers: apiHeaders()
  }).then(result => result.json());
};

export const DistinctVarietyCodes = () => {
  return request(`variety/count/distinctCodes`, {
    headers: apiHeaders()
  }).then(result => result.json());
};

export const DistinctVarietyNames = () => {
  return request(`variety/count/distinctNames`, {
    headers: apiHeaders()
  }).then(result => result.json());
};

import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";

import IconSave from "@material-ui/icons/Save";

import { GradeByBit, GradeByNumbers } from "../lib/components/Grading";

import { genericApi } from "../lib/genericAPI";
import { inspectiontype } from "../lib/storage/inspection";
import { piinstructiontype } from "../lib/storage/piinstructions";
import { ComboSelection } from "../lib/components/ComboSelection";
import { targetmarkettype } from "../lib/storage/targetmarket";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
    },
    list: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
    },
    orderCaption: {
      borderWidth: "1px",
      borderStyle: "solid",
      height: "25px",
      backgroundColor: "lightgreen",
    },
    inspectiondetails: {
      width: "100%",
    },
    saveFab: {
      position: "absolute",
      bottom: "15px",
      right: "25px",
    },
    textAreaWrapper: {
      position: "relative",
      backgroundColor: "white",
      padding: `${theme.spacing.unit}px`,
      fontSize: "14px",
      "& p:first": {
        margin: 0,
        marginBottom: `${theme.spacing.unit}px`,
      },
    },
  });

type InspectionOrdersProps = {
  inspectionid: string;
} & WithStyles<typeof styles>;

const InspectionOrdersUnstyled: React.SFC<InspectionOrdersProps> = (props) => {
  const { classes, inspectionid } = props;
  const [checked, setChecked] = React.useState([]);
  const [ordersData, setOrdersData] = React.useState([]);
  const [inspectionOrders, setInspectionOrders] = React.useState([]);
  const [currentOrder, setCurrentOrder] = React.useState({
    piinstruction_id: undefined,
    weight: undefined,
    firmness: undefined,
    buttons: undefined,
    grading: undefined,
    sizing: undefined,
    color: undefined,
    blemish: undefined,
    skintexture: undefined,
    granulation: undefined,
    general: undefined,
    proposedmarket: undefined,
    proposedcountry: undefined,
    fruitspec: undefined,
  });
  // const [confirmComplete, setConfirmComplete] = React.useState(false);
  const [selectedOrderCaption, setSelectedOrderCaption] = React.useState();
  const [inspectionPIOrder, setInspectionPIOrder] = React.useState();
  const [inspectionPackedMarket, setInspectionPackedMarket] = React.useState();
  const [inspectionPackedCountry, setInspectionPackedCountry] = React.useState();
  const [targetmarketsData, setTargetmarketsData] = React.useState([]);
  const [targetcountriesData, setTargetcountriesData] = React.useState([]);

  const [flag, setFlag] = React.useState("");
  const [dirty, setDirty] = React.useState(false);

  useEffect(() => {
    loadData();
  }, [inspectionid]);

  const loadData = async () => {
    const targetmarketsGeneric = genericApi<targetmarkettype>("targetmarkets");
    targetmarketsGeneric.all().then((targetmarketsLocal) => {
      const reduceTM = targetmarketsLocal
        .reduce((unique, item) => (unique.includes(item.market) ? unique : [...unique, item.market]), [])
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 1;
        });
      setTargetmarketsData(reduceTM);

      const reduceTC = targetmarketsLocal
        .reduce((unique, item) => (unique.includes(item.country) ? unique : [...unique, item.country]), [])
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 1;
        });
      setTargetcountriesData(reduceTC);

      // LOAD existing
      const inspectionsGeneric = genericApi<inspectiontype>("inspections");
      inspectionsGeneric.findBy("id", inspectionid).then((data) => {
        if (data) {
          setInspectionPIOrder(data.piorder);
          setInspectionPackedMarket(data.packedmarket);
          setInspectionPackedCountry(data.packedcountry);
          filterInstructions(data.piorder, data.packedmarket, data.packedcountry);
          setInspectionOrders(data.inspectionOrders ? data.inspectionOrders : []);
          populateChecked(data.inspectionOrders ? data.inspectionOrders : []);
          setFlag(data.flag);
        }
      });
    });
  };

  const filterInstructions = (piorderid, packedmarket, packedcountry) => {
    const piinstructionsGeneric = genericApi<piinstructiontype>("piinstructions");
    piinstructionsGeneric.all().then((piinstructionsLocal) => {
      const resultpiinstructions = piinstructionsLocal
        .filter((item) => item.orgid == piorderid && item.target_market == packedmarket && item.target_country == packedcountry)
        .sort((a, b) => {
          if (a.groupline > b.groupline) return 1;
          if (a.groupline < b.groupline) return -1;
          return 1;
        });
      setOrdersData(resultpiinstructions);
    });
  };

  const filterTargetsCountry = (piorderid, market) => {
    const targetmarketsGeneric = genericApi<targetmarkettype>("targetmarkets");
    targetmarketsGeneric.all().then((targetmarketsLocal) => {
      const targetCfiltered = targetmarketsLocal.filter((item) => item.market == market);

      const reduceTC = targetCfiltered
        .reduce((unique, item) => (unique.includes(item.country) ? unique : [...unique, item.country]), [])
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 1;
        });
      setTargetcountriesData(reduceTC);
    });
  };

  const handleSelectOrder = (piinstruction_id, caption) => {
    setSelectedOrderCaption(caption);
    const loadedData = inspectionOrders.find((item) => item.piinstruction_id == piinstruction_id);
    if (!loadedData) {
      const newRecord = {
        piinstruction_id: piinstruction_id,
        weight: undefined,
        firmness: undefined,
        buttons: undefined,
        grading: undefined,
        sizing: undefined,
        color: undefined,
        blemish: undefined,
        actionexist: false,
        skintexture: undefined,
        granulation: undefined,
        general: undefined,
        proposedmarket: inspectionPackedMarket,
        proposedcountry: inspectionPackedCountry,
        fruitspec: undefined,
      };
      filterTargetsCountry(inspectionPIOrder, inspectionPackedMarket);
      setCurrentOrder(newRecord);
    } else {
      filterTargetsCountry(inspectionPIOrder, loadedData.proposedmarket);
      setCurrentOrder(loadedData);
    }
  };

  const handleSave = async () => {
    setSelectedOrderCaption(undefined);

    const completedFields = [];

    Promise.all(
      Object.keys(currentOrder).map((key) => {
        if (currentOrder[key] && key != "piinstruction_id" && key != "proposedmarket" && key != "proposedcountry") {
          completedFields.push({ name: key, value: currentOrder[key] });
        }
      }),
    ).then(() => {
      const newInspectionOrders = [];
      if (completedFields.length > 0) {
        newInspectionOrders.push(currentOrder);
      }
      if (inspectionOrders && inspectionOrders.length > 0) {
        inspectionOrders.map((item) => {
          if (item.piinstruction_id !== currentOrder.piinstruction_id) {
            newInspectionOrders.push(item);
          }
        });
      }
      populateChecked(newInspectionOrders);
      setInspectionOrders(newInspectionOrders);
      handleSaveInspectionOrders(newInspectionOrders);
    });
  };

  const handleClear = async () => {
    setSelectedOrderCaption(undefined);

    const completedFields = [];

    Promise.all(
      Object.keys(currentOrder).map((key) => {
        if (currentOrder[key] && key != "piinstruction_id" && key != "proposedmarket" && key != "proposedcountry") {
          completedFields.push({ name: key, value: currentOrder[key] });
        }
      }),
    ).then(() => {
      const newInspectionOrders = [];
      if (inspectionOrders && inspectionOrders.length > 0) {
        inspectionOrders.map((item) => {
          if (item.piinstruction_id !== currentOrder.piinstruction_id) {
            newInspectionOrders.push(item);
          }
        });
      }
      populateChecked(newInspectionOrders);
      setInspectionOrders(newInspectionOrders);
      handleSaveInspectionOrders(newInspectionOrders);
    });
  };

  const handleSaveInspectionOrders = (ordersToSave) => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      const newData = {
        inspectionOrders: ordersToSave,
      };
      inspectionsGeneric.update(inspectionid, { ...data, ...newData });
    });
  };

  const populateChecked = (orders) => {
    const checked = [];
    orders.map((item) => {
      checked.push(item.piinstruction_id);
    });
    setChecked(checked);
  };

  const handleChange = (name) => (value) => {
    let newCurrentOrder = { ...currentOrder, [name]: value };
    if (name == "proposedmarket") {
      newCurrentOrder = { ...newCurrentOrder, proposedcountry: undefined };
      filterTargetsCountry(inspectionPIOrder, newCurrentOrder.proposedmarket);
    }

    setCurrentOrder(newCurrentOrder);
  };

  const handleFlagChange = (event) => {
    setFlag(event.target.value);
    setDirty(true);
  };

  const handleSaveInspection = async () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then(async (data) => {
      const newData = {
        flag: flag,
      };
      await inspectionsGeneric.update(inspectionid, { ...data, ...newData });
      setDirty(false);
    });
  };

  return (
    <div className={classes.root}>
      {!selectedOrderCaption && (
        <List dense className={classes.list}>
          {ordersData.map((value) => {
            const labelId = `checkbox-list-secondary-label-${value.id}`;
            const caption = `${value.target_market} | ${value.target_country} | ${value.Variety_Code} | ${value.classv} | ${value.Palletspec_Code}-${value.Palletspec_Carton} | ${value.count} | ${value.brand} | ${value.inventory_code}`;
            return (
              <ListItem
                key={value.id}
                button
                onClick={() => {
                  handleSelectOrder(value.piinstruction_id, caption);
                }}
                style={{ backgroundColor: currentOrder && value.piinstruction_id == currentOrder.piinstruction_id ? "lightgreen" : "white" }}
              >
                <ListItemText id={labelId} primary={caption} disableTypography style={{ whiteSpace: "nowrap" }} />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={checked.indexOf(value.piinstruction_id) !== -1}
                    onClick={() => {
                      handleSelectOrder(value.piinstruction_id, caption);
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      {selectedOrderCaption && (
        <Paper className={classes.orderCaption} onClick={handleSave}>
          <ListItemText
            id={`selectedOrderCaptionMain`}
            primary={selectedOrderCaption}
            style={{ paddingLeft: "3px", whiteSpace: "nowrap", height: "30px", fontSize: "small" }}
            onClick={handleSave}
            disableTypography={true}
          />
        </Paper>
      )}
      {selectedOrderCaption && currentOrder.piinstruction_id && (
        <>
          <div style={{ marginTop: "5px" }}>
            <Button variant="contained" color="primary" style={{ backgroundColor: "green", width: "100px", marginRight: "5px" }} onClick={handleSave}>
              save
            </Button>
            <Button variant="contained" color="primary" style={{ backgroundColor: "red", width: "100px", marginRight: "5px" }} onClick={handleClear}>
              clear
            </Button>
          </div>
          <div className={classes.inspectiondetails}>
            <table>
              <tbody>
                {tableRowItem("FRUIT SPEC:", "fruitspec", inspectionid, currentOrder, handleChange)}
                {tableRowItem("GENERAL:", "general", inspectionid, currentOrder, handleChange)}
                {tableRowItem("WEIGHT:", "weight", inspectionid, currentOrder, handleChange)}
                {tableRowItem("FIRMNESS:", "firmness", inspectionid, currentOrder, handleChange)}
                {tableRowItem("BUTTONS:", "buttons", inspectionid, currentOrder, handleChange)}
                {tableRowItemNum("COLOUR:", "color", inspectionid, currentOrder, handleChange)}
                {tableRowItemNum("BLEMISH:", "blemish", inspectionid, currentOrder, handleChange)}
                {tableRowItemNum("SKIN TEXTURE:", "skintexture", inspectionid, currentOrder, handleChange)}
                {tableRowItemNum("GRANULATION:", "granulation", inspectionid, currentOrder, handleChange)}
                {tableRowItem("GRADING:", "grading", inspectionid, currentOrder, handleChange)}
                {tableRowItem("SIZING:", "sizing", inspectionid, currentOrder, handleChange)}
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td>
                    <ComboSelection
                      classes={classes}
                      name="proposedmarket"
                      selected={currentOrder.proposedmarket}
                      setSelected={handleChange("proposedmarket")}
                      data={targetmarketsData.map((item) => {
                        return { id: item, code: item, name: item };
                      })}
                    />
                  </td>
                  <td>
                    <ComboSelection
                      classes={classes}
                      name="proposedcountry"
                      selected={currentOrder.proposedcountry}
                      setSelected={handleChange("proposedcountry")}
                      data={targetcountriesData.map((item) => {
                        return { id: item, code: item, name: item };
                      })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      <div className={classes.textAreaWrapper}>
        <p>Comment:</p>
        <TextField multiline value={flag} rows={5} onChange={handleFlagChange} style={{ width: "100%" }} placeholder="Add comment here" />
        {dirty && (
          <Fab color="secondary" aria-label="Add" size="small" className={classes.saveFab} onClick={handleSaveInspection}>
            <IconSave />
          </Fab>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(InspectionOrdersUnstyled);

const tableRowItem = (caption: string, field, inspectionid, currentOrder, handleChange) => {
  return (
    <tr>
      <td>{`${caption}`}</td>
      <td>
        <GradeByBit
          inspectionid={inspectionid}
          field={`${field}`}
          piinstruction_id={currentOrder.piinstruction_id}
          value={currentOrder[field]}
          handleGradeChange={handleChange(`${field}`)}
        />
      </td>
    </tr>
  );
};

const tableRowItemNum = (caption: string, field, inspectionid, currentOrder, handleChange) => {
  return (
    <tr>
      <td>{`${caption}`}</td>
      <td>
        <GradeByNumbers
          inspectionid={inspectionid}
          field={`${field}`}
          piinstruction_id={currentOrder.piinstruction_id}
          value={currentOrder[field]}
          handleGradeChange={handleChange(field)}
        />
      </td>
    </tr>
  );
};

import React, { useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import { WSSContext } from "./WSSContext";

import { Loading } from "./base";

type Hydratable = {
  loading: Loading;
  hydrate();
};

const shouldLoad = (loading: Loading) => {
  return !loading.loading && !loading.loaded && !loading.error;
};

export const hydrateAll = (items: Hydratable[], userLoading: Loading) => {
  return useEffect(() => {
    if (userLoading.loaded) {
      items.forEach((item) => {
        if (shouldLoad(item.loading)) {
          item.hydrate();
        }
      });
    }
  }, [userLoading, ...items.map((item) => item.loading)]);
};

export const HydrateApp = () => {
  const { user, loading: userLoading } = useContext(UserContext);
  hydrateAll([useContext(WSSContext)], userLoading);

  return null;
};

export const HydrateUser = () => {
  const { hydrate, loading } = useContext(UserContext);
  useEffect(() => {
    if (shouldLoad(loading)) {
      hydrate();
    }
  }, [loading]);
  return null;
};

import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import ReactDOM from "react-dom";

import { Confirmation } from "../lib/components/confirmation";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import IconClose from "@material-ui/icons/Close";
import IconDelete from "@material-ui/icons/DeleteForever";
import IconRotate from "@material-ui/icons/Rotate90DegreesCcw";

import { genericApi } from "../lib/genericAPI";
import { inspectiontype } from "../lib/storage/inspection";

import ClCamera from "../camera/clcamera";

import LZString from "lz-string";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
      height: "100%",
    },
    appBar: {
      // position: 'relative',
      position: "fixed",
    },
    title: {
      color: "white",
      marginLeft: theme.spacing.unit * 2,
      flex: 1,
    },
    nestedContainer: {
      height: "100%",
      display: "grid",
      gridTemplateAreas: `
    "nested_imageSec"
    `,
      gridTemplateColumns: "1fr",
    },
    // nestedTitle: {
    //   gridArea: "nested_title",
    //   position: "relative",
    //   width: "100%",
    //   height: "35px",
    // },
    nestedImageSec: {
      height: "70vh",
      gridArea: "nested_imageSec",
      position: "relative",
    },
    inspectionGrid: {
      height: "100%",
      display: "grid",
      gridTemplateAreas: `
    "comment_sec"
    "images_sec"
    "actions_sec"
    `,
      gridTemplateColumns: "1fr",
    },
    comment_sec: {
      marginLeft: "10px !important",
      position: "relative",
      gridArea: "comment_sec",
      marginTop: "45px",
      width: "95%",
    },
    images_sec: {
      margin: "0",
      position: "relative",
      gridArea: "images_sec",
      overflowY: "scroll",
      marginBottom: "100px",
      width: "95%",
      marginLeft: "10px",
    },
    actions_sec: {
      marginLeft: "10px",
      bottom: "0",
      position: "fixed",
      width: "100%",
      height: "95px",
      gridArea: "actions_sec",
    },
    imageTitle: {
      position: "fixed",
      backgroundColor: "#fff",
      zIndex: 2,
      width: "100%",
      height: "35px",
    },
  });

type InspectionActionProps = {
  inspectionid: string;
  field: string;
  piinstruction_id: string;
  handleClose: () => void;
  handleConfirm: (action: any) => void;
} & WithStyles<typeof styles>;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const InspectionActionUnstyled: React.FC<InspectionActionProps> = (props) => {
  const { classes, inspectionid, field, piinstruction_id, handleClose, handleConfirm } = props;
  const [comments, setComments] = React.useState("");
  const [pics, setPics] = React.useState([]);
  const [actionsToggled, toggleActions] = React.useState(false);
  const [deleteConfirmationToggled, toggleDeleteConfirmation] = React.useState(false);
  const [imageToDelete, setImageToRemove] = React.useState(undefined);

  useEffect(() => {
    loadData();
  }, [field, piinstruction_id]);

  const loadData = () => {
    // LOAD existing
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      if (data) {
        if (data.actions) {
          const actionexist = data.actions.find((item) => item.field == field && item.piinstruction_id == piinstruction_id);
          if (actionexist) {
            setComments(actionexist.comments);
            setPics(actionexist.images);
          }
        }
      }
    });
  };

  const handleComment = (value) => {
    setComments(value);
  };

  const handleImages = async (imagesCaptured) => {
    const encodedImages = [];
    imagesCaptured.map((image) => {
      image.uri.includes("base64")
        ? encodedImages.push({ base64: LZString.compress(image.uri), width: image.width, height: image.height })
        : encodedImages.push({ base64: image, width: image.width, height: image.height });
    });
    const imagesArr = [...pics];
    imagesArr.push(...encodedImages);
    setPics(imagesArr);
    document.getElementById("divvy").scrollIntoView();
  };

  const fieldname = () => {
    const value = field.toUpperCase();
    if (value.includes("POSTHARVEST")) {
      return "PH" + value.replace("POSTHARVEST", " ");
    } else if (value.includes("LABEL")) {
      return "LBL" + value.replace("LABEL", " ");
    } else if (value.includes("COLOR")) {
      return "COLOUR";
    }
    return value;
  };

  const executeActionToggle = () => {
    toggleActions(!actionsToggled);
  };

  const rotateBase64Image = async (base64data, idx) => {
    // create an off-screen canvas
    const offScreenCanvas = document.createElement("canvas");
    const offScreenCanvasCtx = offScreenCanvas.getContext("2d");

    // create Image
    var img = new Image();
    img.src = base64data;

    // set its dimension to rotated size
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;

    // rotate and draw source image into the off-screen canvas:
    offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
    offScreenCanvasCtx.translate(0, -offScreenCanvas.width);

    offScreenCanvasCtx.drawImage(img, 0, 0);
    const convertedB64String = LZString.compress(offScreenCanvas.toDataURL("image/jpeg", 100));

    const imagesArr = [...pics];

    imagesArr[idx] = { ...imagesArr[idx], base64: convertedB64String };

    setPics(imagesArr);
  };

  const confirmDelete = (index) => {
    setImageToRemove(index);
    toggleDeleteConfirmation(true);
  };

  const deleteCancelled = () => {
    setImageToRemove(undefined);
    toggleDeleteConfirmation(false);
  };

  const deleteConfirmed = () => {
    if (imageToDelete >= -1) {
      pics.splice(imageToDelete, 1);
    }
    setImageToRemove(undefined);
    toggleDeleteConfirmation(false);
  };

  return (
    <div className={classes.root}>
      {deleteConfirmationToggled && (
        <div style={{ margin: "20%" }}>
          <Confirmation
            isOpen={deleteConfirmationToggled}
            handleClose={deleteCancelled}
            handleConfirm={deleteConfirmed}
            title={`Delete confirmation.`}
            body={`Are you certain you want to remove this image?`}
            fullscreen={false}
          />
        </div>
      )}
      <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
        <div className={classes.inspectionGrid}>
          <div>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                  <IconClose />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Actions - {fieldname()}
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
          <div className={classes.comment_sec}>
            <TextField
              id="standard-multiline-flexible"
              label="Comments"
              multiline
              rowsMax="5"
              fullWidth
              value={comments}
              onChange={(e) => {
                handleComment(e.target.value);
              }}
              margin="normal"
            />
          </div>
          <div className={classes.images_sec}>
            {pics && pics.length > 0 && (
              <div className={classes.nestedContainer}>
                <div className={classes.nestedImageSec}>
                  {pics.map((image, idx) => {
                    return (
                      <div key={`picsitem${idx}`} style={{ width: "95%", display: "inline-block" }}>
                        {actionsToggled && (
                          <div style={{ display: "block", width: "inherit" }}>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                rotateBase64Image(image.base64.includes("base64") ? image.base64 : LZString.decompress(image.base64), idx);
                              }}
                            >
                              <IconRotate />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                confirmDelete(idx);
                              }}
                            >
                              <IconDelete style={{ color: "red" }} />
                            </IconButton>
                          </div>
                        )}
                        <img
                          id={image}
                          key={`${image.base64}image${idx}`}
                          src={image.base64.includes("base64") ? image.base64 : LZString.decompress(image.base64)}
                          alt="captured"
                          height="250px"
                          style={{ cursor: "pointer", float: "left", width: "auto", height: "auto", maxHeight: "250px", maxWidth: "100%" }}
                          onClick={() => {
                            executeActionToggle();
                          }}
                        />
                      </div>
                    );
                  })}
                  <div id="divvy" />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actions_sec}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <ClCamera handleImages={handleImages} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="contained"
                      // size="small"
                      color="primary"
                      style={{ height: "40px", minWidth: "40px", margin: "5px" }}
                      onClick={() => {
                        handleConfirm({ field: field, piinstruction_id: piinstruction_id, comments: comments, images: pics });
                      }}
                    >
                      confirm
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(InspectionActionUnstyled);

import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import InspectionsLocal from "../inspections/inspectionslocal";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit,
    },
  });

type HomeProps = {} & WithStyles<typeof styles>;

const HomeUnstyled: React.SFC<HomeProps> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <InspectionsLocal status={0} />
      <InspectionsLocal status={1} />
    </div>
  );
};

export default withStyles(styles)(HomeUnstyled);

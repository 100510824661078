import { client, apiHeaders, request } from "./_base";

export const weeks = client("week", item => ({
  ...item,
}));

export const weeksOrdered = () =>
  request(`week/extend/weekOrdered`, {
    headers: apiHeaders()
  }).then(result => result.json());

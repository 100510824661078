import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider  from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";

import IconClose from "@material-ui/icons/Close";

import { genericApi } from "../lib/genericAPI";
import { piordergroupedtype } from "../lib/storage/piordergrouped";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing.unit,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    color: "white",
    marginLeft: theme.spacing.unit * 2,
    flex: 1,
  },
});

type InstructionGroupedProps = {
  handleSelectionClose: () => void,
  handleSelectInstruction: (orgid) => void,
  producerid: string,
  variety_id: string,
  week_id: string,
} & WithStyles<typeof styles>;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const InstructionGroupedUnstyled: React.SFC<InstructionGroupedProps> = (props) => {
  const { classes, handleSelectionClose, handleSelectInstruction, producerid, variety_id, week_id } = props;
  const [insGroupedData, setInsGroupedData] = React.useState([]);

  useEffect(() => {
    loadData();
  }, [classes]);

  const loadData = async () => {
    const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
    piordergroupedGeneric.all().then(allData => { 
      const filteredData = 
        allData
          .filter(item => item.producer_id == producerid)
          .filter(item => item.variety_id == variety_id)
          .filter(item => item.week_id == week_id)
          .sort((a, b) => {
            if (a.ordernum > b.ordernum) return -1;
            if (a.ordernum < b.ordernum) return 1;
            return -1
          });
      setInsGroupedData(filteredData);
    });
  }
  
  return (
    <div className={classes.root}>
      <Dialog fullScreen open={true} onClose={handleSelectionClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleSelectionClose} aria-label="Close">
              <IconClose />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Available Instructions
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {insGroupedData.map(item => {
            return (
              <div key={`item${item.id}`}>
                <ListItem key={`item${item.id}`} button onClick={() => { handleSelectInstruction(item.orgid) }}>
                  {/* <ListItemText key={`item${item.id}text`} primary={item.ordernum} secondary={`${item.producer_name} | VAR: ${item.variety} | WEEK: ${item.week} | REVISION: ${item.revision}`} /> */}
                  <ListItemText key={`item${item.id}text`} primary={item.ordernum} secondary={`${item.producer_name} | VAR: ${item.variety} | WEEK: ${item.week} | REVISION: ${item.revision}`} />
                </ListItem>
                <Divider />
              </div>
            )
          })}
        </List>
      </Dialog>
    </div>
  )
}

export default withStyles(styles)(InstructionGroupedUnstyled);

import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { inspectiontype } from "../lib/storage/inspection";
import { genericApi } from "../lib/genericAPI";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing.unit,
  },
  tableRow: {
    paddingTop: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
});

type InspectionInternalsFormProps = {
} & WithStyles<typeof styles>;

class InspectionInternalsFormUnstyled extends React.Component<InspectionInternalsFormProps, any> {
  state = {
    classes: undefined,
    handleConfirm: undefined,
    handleClose: undefined,
    handleChange: undefined,
    inspectionid: undefined,
    juice: 0,  
    errormessage: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleConfirm = props.handleConfirm;
    this.state.handleClose = props.handleClose;
    this.state.inspectionid = props.inspectionid;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    // LOAD existing
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", this.state.inspectionid).then(data => {
      if (data) {
        this.setState({
          juice: data.juice ? Number(data.juice) : 0,
        });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.juice < 30 || this.state.juice > 70) {
      // this.setState({ juice: 30 });
      this.handleChange("juice", 30);
      this.handleValuesIncorrectNotify();
    }
    else {
      this.state.handleConfirm();
    }
  }

  handleChange = (stateproperty, value) => {
    // if (stateproperty == "juice") {
    //   if (value < 30) {
    //     value = 30;
    //   }
    //   if (value > 70) {
    //     value = 70;
    //   }
    // }

    this.setState({ [stateproperty]: value }, () => {
      const inspectionsGeneric = genericApi<inspectiontype>("inspections");
      inspectionsGeneric.findBy("id", this.state.inspectionid).then(data => {
        const newData =  { 
          juice: this.state.juice,
        }
        inspectionsGeneric.update(this.state.inspectionid, { ...data, ...newData }).then(() => {
        })
      });
    });
  }

  handleValuesIncorrectNotify = () => {
    this.setState({ errormessage: "value needs to be between 30 and 70" })
  }
  
  handleValuesIncorrectNotifyClose = () => {
    this.setState({ errormessage: undefined })
  }
  
  render() {
    const { classes } = this.state

    return (
      <div className={classes.root} >
        {this.state.errormessage &&
          <Typography variant="body1" gutterBottom style={{ color: "red" }}>
             {this.state.errormessage}
          </Typography>
          }
        <form
          id={`internals`}
          onSubmit={e => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>JUICE</span>
                </td>
                <td className={classes.tableRow}>
                  <TextField
                    className={classes.textfield}
                    label={"juice"}
                    value={this.state.juice == 0 ? undefined : this.state.juice}
                    onChange={e => {
                      this.handleChange("juice", e.target.value);
                    }}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: "10px 0 -5px 0" }}>
            <Button
                style={{marginRight: "10px"}}
                color="secondary"
                variant="outlined"
                onClick={this.state.handleClose}
              >
              <Close />
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={this.handleSubmit}
            >
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(InspectionInternalsFormUnstyled);
import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Resizer from "react-image-file-resizer";
import LZString from "lz-string";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

type ClCameraProps = {
  handleImages: any;
} & WithStyles<typeof styles>;

const ClCameraUnstyled: React.FC<ClCameraProps> = (props) => {
  const { classes, handleImages } = props;

  useEffect(() => {}, [classes]);

  const handleInputOnChange = async (e) => {
    let images = [];
    let promises = [];
    await new Promise((resolve, reject) => {
      Array.from(e.target.files).forEach((file) => {
        promises.push(
          new Promise((res, rej) => {
            try {
              Resizer.imageFileResizer(
                file,
                800,
                800,
                "JPEG",
                25,
                0,
                (uri) => {
                  const imgElement = new Image();
                  imgElement.onload = (imgloaded) => {
                    images.push({ uri: uri, width: imgElement.width, height: imgElement.height });
                    res("ok");
                  };
                  imgElement.src = uri;
                },
                "base64",
              );
            } catch (err) {
              console.log("error: ", err);
              rej(err);
            }
          }),
        );
      });

      Promise.all(promises)
        .then((arrOfResults) => {
          handleImages(images);
          resolve("ok");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <div>
      <input
        type="file"
        multiple={true}
        accept="image/*;capture=camera"
        onChange={handleInputOnChange}
        style={{
          border: "1px solid #ccc",
          display: "inline-block",
          padding: "6px 12px",
          cursor: "pointer",
        }}
        title={`ADD PICTURE`}
      />
    </div>
  );
};

export default withStyles(styles)(ClCameraUnstyled);

import { image } from "./logo";
import { PTSans } from "./font";
import jsPDF from "jspdf";
import { piordergroupedtype } from "../lib/storage/piordergrouped";
import { piinstructiontype } from "../lib/storage/piinstructions";

const columns = {
  order: 1,
  group: 1.2,
  volume: 1.2,
  targetregion: 1.5,
  targetmarket: 0.9,
  targetcountry: 0.9,
  class: 0.9,
  count: 1.1,
  coldroom: 1.4,
  palletspec: 2.1,
  brand: 2.5,
  inventory: 1.5,
  mgp: 3.2,
  fob: 1.3,
  dip: 1.8,
  dipmax: 2.1,
  comment: 3.1,
};
let printDateFormatted;
let week;
let variety;
let farm;
let title;
let exworks;
let fixed;
let page;

const brand = "Brand";
const coldStore = "Cold Store";
const count = "Count";
const Order = "Order";
const volume = "Volume";
const palletSpec = "Pallet Spec";
const inventory = "Inventory";
const dIP = "DIP";
const ExW = "ExW";
const eXPDIPRange = "EXP DIP Range";
const eXPExWRange = "EXP ExW Range";
let currentLineStarting = 4.3;

export const handlePrintPDF = async (primaryOrder: piordergroupedtype, sequencedOrders, palletspecs, producers, weeks, varieties) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "cm",
  });

  doc.addFileToVFS("PTSans.ttf", PTSans);
  doc.addFont("PTSans.ttf", "PTSans", "normal");

  doc.setFont("PTSans"); // set font
  page = 1;

  currentLineStarting = 4.3;

  const resultFarm = producers.filter((item) => {
    return item.code == primaryOrder.farm;
  });
  farm = resultFarm;

  printDateFormatted = new Date(primaryOrder.printdate).toUTCString().split(" ").slice(0, 4).join(" ");

  let primaryOrdervariety = varieties.filter((item) => {
    return parseInt(item.orgid) == parseInt(primaryOrder.variety_id);
  });
  let primaryOrderweek = weeks.filter((item) => {
    return parseInt(item.orgid) == parseInt(primaryOrder.week_id);
  });

  const currentLineSpacing = 0.5;

  Promise.all(
    sequencedOrders.data.reverse().map((result) => {
      variety = varieties.filter((item) => {
        return parseInt(item.orgid) == result.variety_id;
      });

      week = weeks.filter((item) => {
        return parseInt(item.orgid) == result.week_id;
      });

      fixed = result.fixed;
      exworks = result.exworks;

      let dipCaption = result.exworks ? "ExW" : "DIP";

      title = variety[0].name + " (" + variety[0].code + ") " + " - V" + primaryOrder.revision;
      printHeader(doc, farm, week, printDateFormatted);
      currentLineStarting = printDetail(doc, currentLineSpacing, dipCaption, result.instructions, palletspecs);

      page = doc.getNumberOfPages();
      doc.setPage(page);
    }),
  ).then(() => {
    if (currentLineStarting >= 17) {
      page++;
      doc.addPage();
      doc.setPage(page);
    }
    printPageNumbers(doc);
    printROE_Comments(doc, primaryOrder.exchange, primaryOrder.comment);

    const pdfDataUri = doc.output("datauristring");
    const base64 = pdfDataUri.split(",")[1];
    const filename =
      primaryOrderweek[0].week.replace(" ", "") +
      "_" +
      resultFarm[0].name +
      "_" +
      primaryOrdervariety[0].name +
      "(" +
      primaryOrdervariety[0].code +
      ")_V" +
      primaryOrder.revision +
      ".pdf";

    window.postMessage(`${filename},${base64}`, "https://mobile.impalacitrus.co.za/");
  });
};

const printBoxedTitle = (doc: jsPDF, y = undefined) => {
  const verticalPosition = y ? y : currentLineStarting;
  doc.setTextColor(255, 255, 255);
  doc.rect(1.2, verticalPosition - 1.1, 27.3, 1.25, "F"); //black heading
  doc.setFontSize(10);
  doc.text(title, 1.4, verticalPosition - 0.6, null, "left");
  doc.setTextColor(0, 0, 0);
};

const printPageNumbers = (doc: jsPDF) => {
  let currentPage = 1;

  for (currentPage; currentPage <= page; currentPage++) {
    doc.setPage(currentPage);
    doc.setFontSize(10);
    doc.text(`Page ${currentPage} of ${page}`, 28.5, 20.5, null, "right");
  }
};

const printColumn = (title, lineStart, doc: jsPDF, spacing, currentX, data, allignCenterSpacing = 0) => {
  let resetPage = page;
  let currentLine = lineStart;
  let currentGroup = undefined;
  doc.setPage(page);
  doc.setFontSize(10);
  doc.setTextColor(255, 255, 255);

  doc.text(title, allignCenterSpacing + currentX, currentLine, null, "center");

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(8);
  currentLine += spacing;
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    currentGroup = element.data;

    if (currentLine >= 18) {
      resetPage++;
      if (currentX == 1) {
        doc.addPage();
      }
      doc.setPage(resetPage);

      currentLine = 4.3 + spacing;
      if (title == Order) {
        printHeader(doc, farm, week, printDateFormatted);
        printBoxedTitle(doc, 4.3);
      }
      doc.setFontSize(10);
      doc.setTextColor(255, 255, 255);

      doc.text(title, allignCenterSpacing + currentX, currentLine - 0.55, null, "center");

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(8);

      doc.setLineWidth(0.01);

      if (title == Order && index < data.length - 1) {
        if (Math.trunc(currentGroup) == Math.trunc(data[index + 1].data)) {
          doc.setLineWidth(0.01);
        } else {
          doc.setLineWidth(0.05);
        }
      }
      if (index == data.length - 1) {
        doc.setLineWidth(0.05);
      }
      doc.line(1.2, currentLine - 0.5, 28.5, currentLine - 0.5);
    }

    doc.setLineWidth(0.01);

    // line(x1, y1, x2, y2)
    if (title == Order) {
      doc.line(currentX + 0.21, currentLine - 0.35, currentX + 0.21, currentLine + 0.13);
    } else if (title == volume) {
      doc.line(currentX + 0.13, currentLine - 0.35, currentX + 0.13, currentLine + 0.13);
    } else if (title == count) {
      doc.line(currentX + 0.21, currentLine - 0.35, currentX + 0.21, currentLine + 0.13);
    } else if (title == coldStore) {
      doc.line(currentX, currentLine - 0.35, currentX, currentLine + 0.13);
    } else if (title == palletSpec) {
      doc.line(currentX + 0.2, currentLine - 0.35, currentX + 0.2, currentLine + 0.13);
    } else if (title == brand) {
      doc.line(currentX, currentLine - 0.35, currentX, currentLine + 0.13);
    } else if (title == inventory) {
      doc.line(currentX + 0.2, currentLine - 0.35, currentX + 0.2, currentLine + 0.13);
    } else if (title == dIP || title == ExW) {
      doc.line(currentX + 0.35, currentLine - 0.35, currentX + 0.35, currentLine + 0.13);
    } else if (title == eXPDIPRange || title == eXPExWRange) {
      doc.line(currentX + 0.35, currentLine - 0.35, currentX + 0.35, currentLine + 0.13);
    } else {
      doc.line(currentX + 0.13, currentLine - 0.35, currentX + 0.13, currentLine + 0.13);
    }

    doc.line(28.5, currentLine - 0.35, 28.5, currentLine + 0.13);

    doc.text(!element.data ? "" : element.data, allignCenterSpacing + currentX, currentLine, null, "center");

    currentLine += spacing;

    if (title == Order && index < data.length - 1) {
      // doc.line(1.2, currentLine + 0.13,  28.5, currentLine + 0.13);
      doc.line(1.2, currentLine - spacing + 0.13, 28.5, currentLine - spacing + 0.13);
      if (Math.trunc(currentGroup) != Math.trunc(data[index + 1].data)) {
        doc.setLineWidth(0.05);
        doc.line(1.2, currentLine - spacing + 0.13, 28.5, currentLine - spacing + 0.13);
      }
    }

    if (index == data.length - 1) {
      doc.setLineWidth(0.05);
      doc.line(1.2, currentLine - spacing + 0.13, 28.5, currentLine - spacing + 0.13);
    }
  }
  // doc.setPage(page);
  return currentLine + 3;
};

const printHeader = async (doc: jsPDF, farm, week, printdate) => {
  doc.addImage(image, "PNG", 1, 0.5, 7, 2.5);
  doc.setFontSize(16);
  doc.setFont("PTSans", "bold"); // set font
  doc.text("PAKINSTRUKSIE - " + farm[0].name, 9, 1, null, "left");
  doc.setFont("PTSans", "normal"); // set font
  doc.text(`Inname week ${week[0].week} ${title && title.length > 0 ? `- ${title}` : ""}`, 9, 1.8, null, "left");
  doc.text(printdate, 9, 2.6, null, "left");
};

const printROE_Comments = (doc: jsPDF, exchange, comments) => {
  doc.setFontSize(10);
  doc.text("RATE OF EXCHANGE", 1, 16.1);
  doc.setLineWidth(0.01);
  doc.line(1, 16.35, 4.1, 16.35, "S"); //  Top
  doc.line(1, 16.35, 1, 19.9, "S"); //  Left
  doc.line(1, 19.9, 4.1, 19.9, "S"); //  Bottom
  doc.line(4.1, 16.35, 4.1, 19.9, "S"); //  Right
  doc.text("USD", 1.6, 17.5);
  doc.text(":  " + exchange.usd, 2.4, 17.5);
  doc.text("CAD", 1.6, 18);
  doc.text(":  " + exchange.cad, 2.4, 18);
  doc.text("EUR", 1.6, 18.5);
  doc.text(":  " + exchange.eur, 2.4, 18.5);
  doc.text("GBP", 1.6, 19);
  doc.text(":  " + exchange.gbp, 2.4, 19);

  // comments
  if (comments && comments.length > 0) {
    doc.text("COMMENTS", 5, 16.1);
    doc.setLineWidth(0.01);

    //Comment Box
    doc.line(5, 16.35, 28.5, 16.35, "S"); //  Top
    doc.line(5, 16.35, 5, 19.9, "S"); //  Left
    doc.line(5, 19.9, 28.5, 19.9, "S"); //  Bottom
    doc.line(28.5, 16.35, 28.5, 19.9, "S"); //  Right

    let fromTop = 16.5,
      leftSide = 5.1;

    comments.split("\n").map((comment) => {
      // any text length greater than 153 should wrap to the next line
      if (comment.toString().length < 153) {
        const text = comment.toString();
        doc.text(text, leftSide, fromTop + 0.19, null, "left");
        fromTop = fromTop + 0.44;
      } else {
        let wrapped = doc.splitTextToSize(comment.toString(), 28.2 - 5.1);
        wrapped.map((text) => {
          doc.text(text, leftSide, fromTop + 0.19, null, "left");
          fromTop = fromTop + 0.44;
        });
      }
    });
  }
};

const printDetail = (doc: jsPDF, currentLineSpacing, dipCaption, instructions: piinstructiontype[] = [], palletspecs = []) => {
  if (currentLineStarting >= 18) {
    page++;
    doc.addPage();
    doc.setPage(page);
    currentLineStarting = 4.3;
    printHeader(doc, farm, week, printDateFormatted);
  }
  printBoxedTitle(doc);

  let currentX = 0;
  doc.setFontSize(10);
  doc.setTextColor(0, 0, 0);

  currentX += columns.order;
  printColumn(
    Order,
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.groupnum + "." + item.groupline, comment: item.comment };
    }),
    0.8,
  );
  currentX += columns.volume;
  printColumn(
    volume,
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.volume, comment: item.comment };
    }),
    0.9,
  );

  currentX += columns.targetregion;
  printColumn(
    "TR",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.target_region, comment: item.comment };
    }),
    0.6,
  );
  currentX += columns.targetmarket;
  printColumn(
    "TM",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.target_market, comment: item.comment };
    }),
    0.6,
  );
  currentX += columns.targetcountry;
  printColumn(
    "TC",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.target_country, comment: item.comment };
    }),
    0.6,
  );
  currentX += columns.class;
  printColumn(
    "Class",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.classv, comment: item.comment };
    }),
    0.7,
  );
  currentX += columns.count;
  printColumn(
    "Count",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      let countResult = item.count;
      if (countResult.substring(0, 1) == "/") {
        countResult = countResult.substring(1, countResult.length);
      }
      const countsArr = countResult.split("/");
      return { data: countsArr.join(" / "), comment: item.comment };
    }),
    0.8,
  );

  currentX += columns.coldroom;
  printColumn(
    "Cold Store",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.coldroom, comment: item.comment };
    }),
    1.1,
  );

  currentX += columns.palletspec;
  printColumn(
    "Pallet Spec",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      const ps = palletspecs.filter((psitem) => {
        return psitem.orgid == item.Palletspec_Id;
      });
      if (ps.length > 0) {
        return { data: item.Palletspec_Name.length > 0 ? ps[0].code + " (" + ps[0].carton + ")" : "", comment: item.comment };
      } else {
        return { data: "", comment: item.comment };
      }
    }),
    1.3,
  );

  currentX += columns.brand;
  printColumn(
    "Brand",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.brand, comment: item.comment };
    }),
    0.8,
  );

  currentX += columns.inventory;
  printColumn(
    "Inventory",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.inventory_description, comment: item.comment };
    }),
    1.8,
  );

  currentX += columns.mgp;
  printColumn(
    "Terms",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.mgp, comment: item.comment };
    }),
    0.8,
  );

  if (fixed) {
    currentX += columns.fob;
    printColumn(
      `${exworks ? "ExW" : ""} Purchase Price`,
      currentLineStarting,
      doc,
      currentLineSpacing,
      currentX,
      instructions.map((ins) => {
        return { data: ins.currency.toUpperCase() + " " + ins.fob, comment: ins.comment };
      }),
      exworks ? 1.8 : 1.8,
    );
  } else {
    currentX += columns.fob;
    printColumn(
      "FOB",
      currentLineStarting,
      doc,
      currentLineSpacing,
      currentX,
      instructions.map((ins) => {
        return { data: ins.currency.toUpperCase() + " " + ins.fob, comment: ins.comment };
      }),
      1.1,
    );

    currentX += columns.dip;
    printColumn(
      dipCaption,
      currentLineStarting,
      doc,
      currentLineSpacing,
      currentX,
      instructions.map((ins) => {
        return { data: ins.dip, comment: ins.comment };
      }),
      1.5,
    );

    currentX += columns.dipmax;
    printColumn(
      `EXP ${dipCaption} Range`,
      currentLineStarting,
      doc,
      currentLineSpacing,
      currentX,
      instructions.map((ins) => {
        const dipRange = () => {
          if (ins.mgp == "FIXED") {
            return "-";
          } else {
            if (ins.dipmin == ins.dipmax) {
              return ins.dipmin.toString();
            } else {
              return ins.dipmin.toString() + " - " + ins.dipmax.toString();
            }
          }
        };
        return { data: dipRange(), comment: ins.comment };
      }),
      !fixed ? 1.8 : 1.7,
    );
  }
  currentX += columns.comment;
  return printColumn(
    "Comment",
    currentLineStarting,
    doc,
    currentLineSpacing,
    currentX,
    instructions.map((item) => {
      return { data: item.comment, comment: item.comment };
    }),
    !fixed ? 1.1 : 3,
  );
};

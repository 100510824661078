import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import numeral from "numeral";
import { inspectiontype } from "../lib/storage/inspection";
import { genericApi } from "../lib/genericAPI";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing.unit,
  },
  tableRow: {
    paddingTop: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
});

type InspectionInternalsFormProps = {
} & WithStyles<typeof styles>;

class InspectionInternalsFormUnstyled extends React.Component<InspectionInternalsFormProps, any> {
  state = {
    classes: undefined,
    handleConfirm: undefined,
    handleClose: undefined,
    handleChange: undefined,
    inspectionid: undefined,
    brix: 0,
    acid: 0,
    ratio: 0,  
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleConfirm = props.handleConfirm;
    this.state.handleClose = props.handleClose;
    this.state.inspectionid = props.inspectionid;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    // LOAD existing
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", this.state.inspectionid).then(data => {
      if (data) {
        this.setState({
          brix: data.brix ? Number(data.brix) : 0,
          acid: data.acid ? Number(data.acid) : 0,
        }, () => {
          this.setState({ ratio: numeral((this.state.brix / this.state.acid)).format("0.00") });    
        })
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.state.handleConfirm();
  }

  handleChange = (stateproperty, value) => {
    if (stateproperty == "brix") {
      if (value < 0) {
        value = 0;
      }
      if (value > 19.99) {
        value = 19.99;
      }
    }

    if (stateproperty == "acid") {
      if (value < 0) {
        value = 0;
      }
      if (value > 9.99) {
        value = 9.99;
      }
    }

    this.setState({ [stateproperty]: value }, () => {
      this.setState({ ratio: numeral((this.state.brix / this.state.acid)).format("0.00") }, () => {
        const inspectionsGeneric = genericApi<inspectiontype>("inspections");
        inspectionsGeneric.findBy("id", this.state.inspectionid).then(data => {
          const newData =  { 
            brix: this.state.brix,
            acid: this.state.acid,
          }
          inspectionsGeneric.update(this.state.inspectionid, { ...data, ...newData }).then(() => {
          })
        });
      });
    });
  }

  render() {
    const { classes } = this.state

    return (
      <div className={classes.root} >
        <form
          id={`internals`}
          onSubmit={e => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>BRIX</span>
                </td>
                <td className={classes.tableRow}>
                  <TextField
                    className={classes.textfield}
                    label={"BRIX"}
                    value={this.state.brix == 0 ? undefined : this.state.brix}
                    onChange={e => {
                      this.handleChange("brix", e.target.value);
                      // this.setState({ brix: e.target.value });
                    }}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>ACID</span>
                </td>
                <td className={classes.tableRow}>
                  <TextField
                    className={classes.textfield}
                    label={"ACID"}
                    value={this.state.acid == 0 ? undefined : this.state.acid}
                    onChange={e => {
                      this.handleChange("acid", e.target.value);
                    }}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  {`RATIO`}
                </td>
                <td className={classes.tableRow}>
                  {`${this.state.ratio}`}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: "10px 0 -5px 0" }}>
            <Button
                style={{marginRight: "10px"}}
                color="secondary"
                variant="outlined"
                onClick={this.state.handleClose}
              >
              <Close />
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={this.handleSubmit}
            >
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(InspectionInternalsFormUnstyled);
import { config } from "../../config";
import { gzipSync, gzip } from "zlib";
import { Buffer } from "buffer";

export const apiHeaders = (content: { length: number } = { length: 0 }, options: {} = {}) =>
  new Headers({
    Accept: "application/json",
    "Content-Length": content.length + "",
    "Content-Type": "application/json",
    "X-csrf-validation": "-",
    ...options
  });

// "Content-Encoding": "gzip",

export const request = (path: string, options: RequestInit = {}) => {
  return fetch(`${config.API_ADDRESS}/api/${path}`, {
    headers: apiHeaders((options.body as any) || ""),
    ...options,
    credentials: "include"
  });
};

export const login = async (fields: { username: string; password: string }) => {
  const data = new FormData();
  data.set("username", fields.username);
  data.set("password", fields.password);
  const response = await request(`login`, {
    method: "POST",
    body: JSON.stringify(fields)
  });
  return response.json();
};

export const logout = async () => {
  const response = await request("logout", {
    method: "GET"
  });
  return response.json();
};

export const whoami = async () => {
  const response = await request("whoami", {
    method: "GET"
  });
  return response.json();
};

export const all = (endpoint: String, transform = (item: any) => item) => () =>
  request(`${endpoint}`, {
    headers: apiHeaders()
  })
    .then(result => result.json())
    .then(({ data }) => (data ? data.map((item: any) => item) : []));
// .then(({ data }) => data.map((item: any) => transform(item)));

export const single = (endpoint: String, transform = (item: any) => item) => (id: any) =>
  request(`${endpoint}/${id}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(({ data }) => data);
// .then(({ data }) => transform(data));

export const create = (endpoint: String, transform = (item: any) => item) => (item: any) => {
  const content = JSON.stringify(item);
  // const content = Buffer.from(JSON.stringify(item));
  // const newcontent = gzipSync(content);
  return request(`${endpoint}`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content
  })
    .then(result => {
      const resultJSON = result.json();
      return resultJSON;
    })
    .then(({ data }) => data);
  // .then(({ data }) => transform(data));
};

export const update = (endpoint: String, transform = (item: any) => item) => (id: any, item: any) => {
  const content = JSON.stringify(item);
  return request(`${endpoint}/${id}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content
  })
    .then(result => result.json())
    .then(({ data }) => data);
  // .then(({ data }) => transform(data));
};

export const remove = (endpoint: String, transform = (item: any) => item) => (id: any) => {
  return request(`${endpoint}/${id}`, {
    method: "DELETE"
  })
    .then(result => result.json())
    .then(({ data }) => data);
  // .then(({ data }) => transform(data));
};

export const newer = (endpoint: String, transform = (item: any) => item) => (date: any) => {
  return request(`${endpoint}/newer/${date}`, {
    method: "GET"
  })
    .then(result => result.json())
    .then(result => {
      return result;
    });
  // .then(({ data }) => data  ? data.map((item: any) => item) : []);
  // .then(({ data }) => data);
  // .then(({ data }) => transform(data));
};

export const client = (endpoint: String, transform = (item: any) => item) => ({
  all: all(endpoint, transform),
  single: single(endpoint, transform),
  update: update(endpoint, transform),
  create: create(endpoint, transform),
  remove: remove(endpoint, transform),
  newer: newer(endpoint, transform)
});

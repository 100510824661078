import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import IconShare from "@material-ui/icons/Share";

import numeral from "numeral";

import { ComboSelection } from "../lib/components/ComboSelection";
import { GradeByBit } from "../lib/components/Grading";
import { Confirmation } from "../lib/components/confirmation";

import { genericApi } from "../lib/genericAPI";
import { inspectiontype } from "../lib/storage/inspection";
import { piordergroupedtype } from "../lib/storage/piordergrouped";
import { piinstructiontype } from "../lib/storage/piinstructions";

import Instructiongrouped from "../instruction/instructiongrouped";
import InspectionPHTreatment from "./inspectionphtreatment";
import InspectionPHLabel from "./inspectionphlabel";
import InspectionFacility from "./inspectionfacility";
import InspectionInternals from "./inspectioninternals";
import InspectionJuice from "./inspectionjuice";
import InspectionLabTest from "./inspectionlabtest";

import { inspectionlabelreqtype } from "../lib/storage/inspectionlabelrequirements";
import { specialmarkettype } from "../lib/storage/specialmarket";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: `${theme.spacing.unit * 3}px`,
      paddingRight: `${theme.spacing.unit * 3}px`,
    },
    root: {
      width: "100%",
    },
    grid: {
      padding: theme.spacing.unit,
    },
    comboselectionform: {
      float: "left",
      display: "grid",
      width: "100%",
    },
    button: {
      padding: theme.spacing.unit / 2,
      width: "100%",
    },
    buttonWrapper: {
      width: "100%",
    },
    paper: {
      padding: theme.spacing.unit,
      width: "300px",
    },
    tableCellLabel: {
      textAlign: "right",
      fontSize: "14px",
      padding: "0px",
      width: "120px",
    },
    tableCellButton: {
      fontSize: "14px",
      padding: "0px",
      paddingLeft: "4px",
      paddingRight: "4px",
      textAlign: "right",
      // width: "120px",
    },
    rowDetail: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "120px 1fr",
      gap: `${theme.spacing.unit * 2}px`,
    },
    marketContainer: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      gap: `${theme.spacing.unit * 2}px`,
      whiteSpace: "nowrap",
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      width: "100%",
    },
    wrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: `${theme.spacing.unit}px`,
    },
  });

type Inspection2Props = {
  inspectionid: string;
} & WithStyles<typeof styles>;

const Inspection2Unstyled: React.SFC<Inspection2Props> = (props) => {
  const { classes, inspectionid } = props;

  const [showInstructionSelection, setShowInstructionSelection] = React.useState(false);
  const [selectedOrderDetail, setSelectedOrderDetail] = React.useState([]);
  const [targetmarketsDataFiltered, setTargetmarketsDataFiltered] = React.useState([]);
  const [targetcountriesDataFiltered, setTargetcountriesDataFiltered] = React.useState([]);
  const [phlabelView, setPhlabelView] = React.useState(false);
  const [facilityView, setFacilityView] = React.useState(false);
  const [labtestView, setLabtestView] = React.useState(false);
  const [requireConsignee, setRequireConsignee] = React.useState(false);
  const [requireCountry, setRequireCountry] = React.useState(false);
  const [inspectionLabelRequirements, setInspectionLabelRequirements] = React.useState([]);
  const [ordersHaveBeenInspected, setOrdersHaveBeenInspected] = React.useState(false);
  const [confirmReset, setConfirmReset] = React.useState(false);
  const [groupnumber, setGroupnumber] = React.useState("");

  const [state, setState] = React.useState({
    producer: undefined,
    packedmarket: undefined,
    packedcountry: undefined,
    postharvestlabel: undefined,
    postharvesttreatment: undefined,
    labelconsignee: undefined,
    labelcountry: undefined,
    piorder: undefined,
    specialmarket: undefined,
    facility: undefined,
    brix: undefined,
    acid: undefined,
    juice: undefined,
    variety_id: undefined,
    week_id: undefined,
    groupnumber: undefined,
    labtest: undefined,
    puc: undefined,
    orchard: undefined,
    variety: undefined,
    degreen: undefined,
  });

  const [internalsView, setInternalsView] = React.useState(false);
  const [juiceView, setJuiceView] = React.useState(false);

  useEffect(() => {
    loadData();
  }, [inspectionid, state.piorder]);

  const loadData = async () => {
    const inspectionLabelRequirements = genericApi<inspectionlabelreqtype>("inspectionlabelreqs");
    inspectionLabelRequirements.all().then((requirements) => {
      setInspectionLabelRequirements(requirements);

      // LOAD existing
      const inspectionsGeneric = genericApi<inspectiontype>("inspections");
      inspectionsGeneric.findBy("id", inspectionid).then((data) => {
        if (data) {
          setState({
            producer: data.producer,
            packedmarket: data.packedmarket,
            packedcountry: data.packedcountry,
            postharvestlabel: data.postharvestlabel,
            postharvesttreatment: data.postharvesttreatment,
            labelconsignee: data.labelconsignee,
            labelcountry: data.labelcountry,
            piorder: data.piorder,
            specialmarket: data.specialmarket,
            facility: data.facility,
            brix: data.brix,
            acid: data.acid,
            juice: data.juice,
            variety_id: data.variety_id,
            week_id: data.week_id,
            groupnumber: data.groupnumber,
            labtest: data.labtest,
            puc: data.puc,
            orchard: data.orchard,
            variety: data.variety_id,
            degreen: data.degreen,
          });
          loadPIOrderDetailString(data.piorder, data.specialmarket, data.packedmarket);
          loadLabelRequirements(data.packedmarket, data.packedcountry, requirements);
          if (data.inspectionOrders && data.inspectionOrders.length > 0) {
            setOrdersHaveBeenInspected(true);
          }
          if (data.groupnumber) {
            setGroupnumber(data.groupnumber);
          }
        }
      });
    });
  };

  const handleChange = (name) => async (value) => {
    let newState = { ...state, [name]: value };
    setState(newState);

    if (name == "piorder") {
      setShowInstructionSelection(false);
      loadPIOrderDetailString(newState.piorder, undefined, undefined);
    }

    if (name == "specialmarket") {
      loadPIOrderDetailString(newState.piorder, value, newState.packedmarket);
    }

    if (name == "packedmarket") {
      newState = { ...newState, packedcountry: undefined };
      newState = { ...newState, labelconsignee: 2, labelcountry: 2 };
      const newStatePackedUpdate = { ...newState, proposedmarket: value };
      setState(newStatePackedUpdate);
      loadLabelRequirements(value, newState.packedcountry, inspectionLabelRequirements);
      filterTargetsCountry(newState.piorder, newState.packedmarket);
    }

    if (name == "packedcountry") {
      loadLabelRequirements(newState.packedmarket, newState.packedcountry, inspectionLabelRequirements);
    }

    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      const newData = {
        packedmarket: newState.packedmarket,
        packedcountry: newState.packedcountry,
        postharvestlabel: newState.postharvestlabel,
        postharvesttreatment: newState.postharvesttreatment,
        labelconsignee: newState.labelconsignee,
        labelcountry: newState.labelcountry,
        piorder: newState.piorder,
        facility: newState.facility,
        brix: newState.brix,
        acid: newState.acid,
        juice: newState.juice,
        specialmarket: newState.specialmarket,
        labtest: newState.labtest,
        degreen: newState.degreen,
      };
      inspectionsGeneric.update(inspectionid, { ...data, ...newData }).then(() => {
        inspectionsGeneric.findBy("id", inspectionid).then((data) => {});
      });
    });
  };

  const loadPIOrderDetailString = (piorder, specialmarket, packedmarket) => {
    const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
    piordergroupedGeneric.findBy("orgid", piorder).then((data) => {
      if (data) {
        const detailstring = `${data.ordernum}`;
        setSelectedOrderDetail(detailstring.split("#"));
        if (packedmarket) {
          filterTargetsCountry(data.orgid, packedmarket);
        }
        if (specialmarket) {
          filterTargetsMarket(data.orgid, specialmarket);
        }
      }
    });
  };

  const loadLabelRequirements = (packedmarket, packedcountry, requirements) => {
    const exist = requirements.find((req) => req.market == packedmarket && req.country == packedcountry);
    if (exist) {
      setRequireConsignee(exist.requiredconsignee);
      setRequireCountry(exist.requiredcountry);
    } else {
      setRequireConsignee(false);
      setRequireCountry(false);
    }
  };

  const handleOnDialogOpen = () => {
    if (ordersHaveBeenInspected) {
      setConfirmReset(true);
    } else if (groupnumber) {
      setConfirmReset(true);
    } else {
      setShowInstructionSelection(true);
    }
  };

  const handleOnDialogClose = () => {
    setShowInstructionSelection(false);
  };

  const handlePHLabelClick = () => {
    setPhlabelView(true);
  };

  const handlePHLabelClickClose = () => {
    handleChange("postharvestlabel")("0");
    setPhlabelView(false);
  };

  const handlePHLabelClickConfirm = () => {
    handleChange("postharvestlabel")("1");
    setPhlabelView(false);
  };

  const handleFacilityClick = () => {
    setFacilityView(true);
  };

  const handleFacilityClickClose = () => {
    handleChange("facility")("0");
    setFacilityView(false);
  };

  const handleFacilityClickConfirm = () => {
    handleChange("facility")("1");
    setFacilityView(false);
  };

  const handleLabTestClick = () => {
    setLabtestView(true);
  };

  const handleLabTestAction = (value: number) => {
    handleChange("labtest")(value);
    setLabtestView(false);
  };

  const filterTargetsMarket = (piorderid, specialmarket) => {
    const piinstructionsGeneric = genericApi<piinstructiontype>("piinstructions");
    piinstructionsGeneric.all().then((piinstructionsLocal) => {
      const resultFilteredMarket = [];
      piinstructionsLocal
        .filter((item) => item.orgid == piorderid)
        .map((item) => {
          const resultexist = resultFilteredMarket.find((exist) => exist.target_market == item.target_market);
          if (!resultexist) {
            resultFilteredMarket.push(item);
          }
        });

      if (resultFilteredMarket && resultFilteredMarket.length > 0) {
        const reduceTM = resultFilteredMarket
          .reduce((unique, item) => (unique.includes(item.target_market) ? unique : [...unique, item.target_market]), [])
          .sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 1;
          });

        if (reduceTM) {
          if (specialmarket && specialmarket == 1) {
            const specialmarketsGeneric = genericApi<specialmarkettype>("specialmarkets");
            specialmarketsGeneric.all().then((specialmarketsLocal) => {
              const reduceTMSpecial = [];
              reduceTM.map((reduceTMItem) => {
                if (specialmarketsLocal.findIndex((item) => item.market == reduceTMItem) >= 0) {
                  reduceTMSpecial.push(reduceTMItem);
                }
              });
              setTargetmarketsDataFiltered(reduceTMSpecial);
            });
          } else {
            setTargetmarketsDataFiltered(reduceTM);
          }
        }
      } else {
        setTargetmarketsDataFiltered([]);
      }
    });
  };

  const filterTargetsCountry = (piorderid, market) => {
    const piinstructionsGeneric = genericApi<piinstructiontype>("piinstructions");
    piinstructionsGeneric.all().then((piinstructionsLocal) => {
      const resultFilteredCountry = [];
      piinstructionsLocal
        .filter((item) => item.orgid == piorderid)
        .filter((item) => item.target_market == market)
        .map((item) => {
          const resultexist = resultFilteredCountry.find((exist) => exist.target_country == item.target_country);
          if (!resultexist) {
            resultFilteredCountry.push(item);
          }
        });

      if (resultFilteredCountry && resultFilteredCountry.length > 0) {
        const reduceTC = resultFilteredCountry
          .reduce((unique, item) => (unique.includes(item.target_country) ? unique : [...unique, item.target_country]), [])
          .sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 1;
          });

        if (reduceTC) {
          setTargetcountriesDataFiltered(reduceTC);
        }
      } else {
        setTargetcountriesDataFiltered([]);
      }
    });
  };

  const handleInternalsClick = () => {
    setInternalsView(true);
  };

  const handleInternalsClickClose = () => {
    setInternalsView(false);
  };

  const handleInternalsClickConfirm = () => {
    setInternalsView(false);
    loadData();
  };

  const handleJuiceClick = () => {
    setJuiceView(true);
  };

  const handleJuiceClickClose = () => {
    setJuiceView(false);
  };

  const handleJuiceClickConfirm = () => {
    setJuiceView(false);
    loadData();
  };

  const handleResetConfirmClose = () => {
    setConfirmReset(false);
  };

  const handleResetConfirmAccept = () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      const newData = {
        inspectionOrders: undefined,
        actions: undefined,
        brix: 0,
        acid: 0,
        facility: undefined,
        postharvestlabel: undefined,
        postharvesttreatment: undefined,
        labelconsignee: undefined,
        labelcountry: undefined,
        juice: 0,
        groupnumber: undefined,
        labtest: undefined,
      };
      inspectionsGeneric.update(inspectionid, { ...data, ...newData }).then(() => {
        setShowInstructionSelection(true);
        loadData();
      });
    });
    setOrdersHaveBeenInspected(false);
    setConfirmReset(false);
  };

  const handleOpenExternalWebsite = () => {
    window.open("https://icaonline.co.za", "_newtab");
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleOnDialogOpen}
          style={{ backgroundColor: selectedOrderDetail ? "gray" : "primary", color: selectedOrderDetail ? "black" : "white" }}
        >
          {selectedOrderDetail && selectedOrderDetail.length > 0
            ? selectedOrderDetail.map((item) => {
                return item;
              })
            : "select instruction"}
        </Button>
      </div>
      {showInstructionSelection && (
        <Instructiongrouped
          handleSelectionClose={handleOnDialogClose}
          handleSelectInstruction={handleChange("piorder")}
          producerid={state.producer}
          variety_id={state.variety_id}
          week_id={state.week_id}
        />
      )}
      <div className={classes.marketContainer}>
        <ComboSelection
          classes={classes}
          name="special market"
          selected={state.specialmarket}
          setSelected={handleChange("specialmarket")}
          data={[
            { id: 1, code: "0", name: "NO" },
            { id: 2, code: "1", name: "YES" },
          ]}
          locked={ordersHaveBeenInspected}
        />
        {state.piorder && state.specialmarket && (
          <>
            <ComboSelection
              classes={classes}
              locked={ordersHaveBeenInspected}
              name="packed markt"
              selected={state.packedmarket}
              setSelected={handleChange("packedmarket")}
              data={
                ordersHaveBeenInspected
                  ? [{ id: state.packedmarket, code: state.packedmarket, name: state.packedmarket }]
                  : targetmarketsDataFiltered.map((item) => {
                      return { id: item, code: item, name: item };
                    })
              }
            />
            <ComboSelection
              classes={classes}
              locked={ordersHaveBeenInspected}
              name="packed cntr"
              selected={state.packedcountry}
              setSelected={handleChange("packedcountry")}
              data={
                ordersHaveBeenInspected
                  ? [{ id: state.packedcountry, code: state.packedcountry, name: state.packedcountry }]
                  : targetcountriesDataFiltered.map((item) => {
                      return { id: item, code: item, name: item };
                    })
              }
            />
          </>
        )}
      </div>

      {state.piorder && state.specialmarket && (
        <div className={classes.wrapper}>
          <div className={classes.rowDetail}>
            <Button variant="outlined" onClick={handleLabTestClick} className={classes.tableCellButton} size={"small"}>
              {`LAB TEST:`}
            </Button>
            <GradeByBit inspectionid={inspectionid} piinstruction_id={0} field="labtest" value={state.labtest} handleGradeChange={handleChange("labtest")} required={false} />
          </div>
          <div className={classes.rowDetail}>
            <Button variant="outlined" onClick={handleInternalsClick} className={classes.tableCellButton}>
              {`INTERNALS:`}
            </Button>
            <Typography variant="h6" style={{ fontWeight: "bold", color: "orange", paddingLeft: "5px" }}>
              {`${state.brix}:${state.acid} - `}
              <span style={{ color: "red" }}>{`${numeral(state.brix / state.acid).format("0.00")}`}</span>
            </Typography>
          </div>
          <div className={classes.rowDetail}>
            <Button variant="outlined" onClick={handleJuiceClick} className={classes.tableCellButton}>
              {`JUICE:`}
            </Button>
            <Typography variant="h6" style={{ fontWeight: "bold", color: "orange", paddingLeft: "5px" }}>
              {`${state.juice} %`}
            </Typography>
          </div>
          <div className={classes.rowDetail}>
            <Button variant="outlined" onClick={handleFacilityClick} className={classes.tableCellButton} size={"small"}>
              {`FACILITY:`}
            </Button>
            <GradeByBit inspectionid={inspectionid} piinstruction_id={0} field="facility" value={state.facility} handleGradeChange={handleChange("facility")} />
          </div>
          <div className={classes.rowDetail}>
            <p>DEGREEN</p>
            <GradeByBit
              inspectionid={inspectionid}
              piinstruction_id={0}
              field="degreen"
              value={state.degreen}
              handleGradeChange={handleChange("degreen")}
              required={false}
              showCameraButton={false}
            />
          </div>
          <div className={classes.rowDetail}>
            <Button variant="outlined" onClick={handlePHLabelClick} className={classes.tableCellButton}>
              {`PH LABELS:`}
            </Button>
            <GradeByBit
              inspectionid={inspectionid}
              piinstruction_id={0}
              field="postharvestlabel"
              value={state.postharvestlabel}
              handleGradeChange={handleChange("postharvestlabel")}
            >
              <Fab color="primary" aria-label="Add" size={"small"} style={{ marginRight: "5px" }} onClick={handleOpenExternalWebsite}>
                <IconShare />
              </Fab>
            </GradeByBit>
          </div>
          <div className={classes.rowDetail}>
            <p>CONSIGNEE LBL:</p>
            <GradeByBit
              inspectionid={inspectionid}
              piinstruction_id={0}
              field="labelconsignee"
              value={state.labelconsignee}
              handleGradeChange={handleChange("labelconsignee")}
              required={requireConsignee}
            />
          </div>
          <div className={classes.rowDetail}>
            <p>COUNTRY LBL:</p>
            <GradeByBit
              inspectionid={inspectionid}
              piinstruction_id={0}
              field="labelcountry"
              value={state.labelcountry}
              handleGradeChange={handleChange("labelcountry")}
              required={requireCountry}
            />
          </div>
        </div>
      )}
      {phlabelView && (
        <Confirmation isOpen={phlabelView} handleClose={handlePHLabelClickClose} handleConfirm={handlePHLabelClickConfirm} title={``} body={``} fullscreen={true}>
          <InspectionPHLabel market={state.packedmarket} handleClose={handlePHLabelClickClose} handleConfirm={handlePHLabelClickConfirm} />
        </Confirmation>
      )}
      {facilityView && (
        <Confirmation isOpen={facilityView} handleClose={handleFacilityClickClose} handleConfirm={handleFacilityClickConfirm} title={``} body={``} fullscreen={true}>
          <InspectionFacility
            piorderid={state.piorder}
            packedmarket={state.packedmarket}
            packedcountry={state.packedcountry}
            handleClose={handleFacilityClickClose}
            handleConfirm={handleFacilityClickConfirm}
          />
        </Confirmation>
      )}
      {internalsView && (
        <Confirmation isOpen={internalsView} handleClose={handleInternalsClickClose} handleConfirm={handleInternalsClickConfirm} title={``} body={``} fullscreen={true}>
          <InspectionInternals handleClose={handleInternalsClickClose} handleConfirm={handleInternalsClickConfirm} inspectionid={inspectionid} />
        </Confirmation>
      )}
      {juiceView && (
        <Confirmation isOpen={juiceView} handleClose={handleJuiceClickClose} handleConfirm={handleJuiceClickConfirm} title={``} body={``} fullscreen={true}>
          <InspectionJuice handleClose={handleJuiceClickClose} handleConfirm={handleJuiceClickConfirm} inspectionid={inspectionid} />
        </Confirmation>
      )}
      {labtestView && (
        <Confirmation
          isOpen={labtestView}
          handleClose={() => {
            handleLabTestAction(0);
          }}
          handleConfirm={() => {
            handleLabTestAction(1);
          }}
          title={``}
          body={``}
          fullscreen={true}
        >
          <InspectionLabTest handleAction={handleLabTestAction} producer_id={state.producer} puc_id={state.puc} orchard_id={state.orchard} variety_id={state.variety_id} />
        </Confirmation>
      )}

      {confirmReset && (
        <Confirmation
          isOpen={true}
          handleClose={handleResetConfirmClose}
          handleConfirm={handleResetConfirmAccept}
          title={`Reset Inspection`}
          body={
            groupnumber
              ? `Inspection Is part of a group. Changing the Packing Instruction will remove the inspection from the group. This will also clear any inspection details entered. Would you like to clear all data?`
              : `Inspection has already been started. Changing the Packing Instruction will reset the Inspection data. This will clear any inspection details entered. Would you like to clear all data?`
          }
        ></Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(Inspection2Unstyled);

import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

export const piorder = client("piorder", item => ({
  ...item,
}));

export const piorderFull = () =>
  request(`piorder/extend/all`, {
    headers: apiHeaders()
  }).then(result => result.json());

export const removeWithInstructions = id =>
  request(`piorder/removeWithIns/${id}`, {
    headers: apiHeaders(),
    method: "DELETE"
  }).then(result => result.json());

export const getOrders = (idList) => 
  request(`piorder/getOrders/${idList}`, {
    headers: apiHeaders()
  }).then(result => result.json());

export const getOrdersByFarmWeek = (farm, week) => 
  request(`piorder/getOrdersByFarmWeek/${farm}|${week}`, {
    headers: apiHeaders()
  }).then(result => result.json());
  
export const getOrdersGrouped = () => 
  request(`piorder/ext/getOrdersGrouped`, {
    headers: apiHeaders()
  }).then(result => result.json());
import { client, request } from "./_base";

export const inspections = client("inspection", (item) => ({
  ...item,
}));

export const findByNumber = (number) => {
  return request(`inspection/ext/findByNumber/${number}`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const findByGroupNumber = (number) => {
  return request(`inspection/ext/findByGroupNumber/${number}`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const findBy = (id = 0, producerid = 0, limit = 1000) => {
  return request(`inspection/ext/inspectionFull/${id}/${producerid}/${limit}`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

export const inspectionPiOrder = () => {
  return request(`inspection/ext/inspectionPiOrder`, {
    method: "GET",
  })
    .then((result) => result.json())
    .then(({ data }) => {
      return data;
    });
};

// export const inspectionfull = (id, limit=1000) => {
//   return request(`inspection/ext/inspectionFull/${id}/${limit}`, {
//     method: "GET"
//   })
//     .then(result => result.json())
//     .then(({ data }) => {
//       return data;
//     });
// };

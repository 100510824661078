import { client } from "./_base";

export const users = client("users", (item) => ({
  ...item,
}));

export type User = {
  id: number;
  username: string;
  admin: boolean;
  active: boolean;
};

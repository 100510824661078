import React from "react";
import { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Badge from "@material-ui/core/Badge";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import SvgIcon from "@material-ui/core/SvgIcon";

import IconLock from "@material-ui/icons/Lock";
import IconMore from "@material-ui/icons/MoreVert";
import IconBugReport from "@material-ui/icons/BugReport";
import IconHome from "@material-ui/icons/Home";
import IconSync from "@material-ui/icons/Sync";
import IconReport from "@material-ui/icons/AssignmentTurnedIn";
import IconMail from "@material-ui/icons/Mail";

import { logout, whoami } from "../lib/api/_base";
import history from "../lib/history";

import Sync from "../sync/sync";

import { WSSContext } from "../lib/context/WSSContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "block",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing.unit * 3,
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing.unit * 9,
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: "flex",
      // [theme.breakpoints.up("md")]: {
      //   display: "none",
      // },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    bottomNavWrapper: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      right: 0,
      background: "#efefef",
    },
  });

type DashboardProps = {} & WithStyles<typeof styles>;

const DashboardUnstyled: React.SFC<DashboardProps> = (props) => {
  const { classes, children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [userVerified, setUserVerified] = React.useState(false);

  const [badgeNum, setBadgeNum] = useState(0);
  const { wss, hydrate } = React.useContext(WSSContext);

  const [user, setUser] = React.useState({
    name: "",
    admin: null,
    active: null,
  });

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    if (navigator.onLine) {
      setBadgeNum(wss.notificationCount);
      whoami()
        .then((user) => {
          setUser({
            name: user.username,
            admin: user.admin,
            active: user.active,
          });
          setUserVerified(true);
        })
        .catch((e) => {
          history.push("/login");
        });
    } else {
      setBadgeNum(0);
      const currentUser = window.localStorage.getItem("currentUser");
      if (currentUser) {
        const currentUserJSON = JSON.parse(currentUser);
        setUser({
          name: currentUserJSON.username,
          admin: currentUserJSON.admin,
          active: currentUserJSON.active,
        });
      }
    }
  }, [wss.message, wss.notificationCount]);

  const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMobileMenuOpen = (event: any) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const handleLogout = () => {
    window.localStorage.removeItem("currentUser");
    window.localStorage.removeItem("currentinspection");
    window.localStorage.setItem("loggedIn", "false");
    handleMobileMenuClose();
    logout();
    history.push("/login");
  };

  const handleInspection = () => {
    handleMobileMenuClose();
    window.localStorage.removeItem("currentinspection");
    history.push("/inspection/0");
  };

  const handleSync = () => {
    handleMobileMenuClose();
    window.localStorage.removeItem("currentinspection");
    history.push("/syncmaster");
  };

  const handleHome = () => {
    handleMobileMenuClose();
    window.localStorage.removeItem("currentinspection");
    history.push("/home");
  };

  const handleInspections = () => {
    handleMobileMenuClose();
    window.localStorage.removeItem("currentinspection");
    history.push("/inspections");
  };

  const handleNotifications = () => {
    handleMobileMenuClose();
    window.localStorage.removeItem("currentinspection");
    history.push("/notifications");
  };

  const handleChangeNav = (newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        history.push("/");
        break;
      case 1:
        history.push("/inspections");
        break;
      case 2:
        history.push("/packinstructions");
        break;
      default:
        break;
    }
  };

  // const renderMobileMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     <MenuItem onClick={handleHome}>
  //       <IconButton color="inherit">
  //         <IconHome />
  //       </IconButton>
  //       <p>HOME</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleInspection}>
  //       <IconButton color="inherit">
  //         <IconBugReport />
  //       </IconButton>
  //       <p>NEW</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleInspections}>
  //       <IconButton color="inherit">
  //         <IconReport />
  //       </IconButton>
  //       <p>COMPLETED</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleSync}>
  //       <IconButton color="inherit">
  //         <IconSync />
  //       </IconButton>
  //       <p>SYNC</p>
  //     </MenuItem>
  //     {navigator.onLine && (
  //       <MenuItem onClick={handleNotifications}>
  //         <IconButton color="inherit">
  //           <Badge badgeContent={badgeNum} color="secondary" style={{}}>
  //             <IconMail />
  //           </Badge>
  //         </IconButton>
  //         <p>NOTIFICATIONS</p>
  //       </MenuItem>
  //     )}
  //     {navigator.onLine && (
  //       <MenuItem onClick={handleLogout}>
  //         <IconButton color="inherit">
  //           <IconLock />
  //         </IconButton>
  //         <p>LOG OUT</p>
  //       </MenuItem>
  //     )}
  //   </Menu>
  // );

  return (
    <div className={classes.root}>
      <AppBar style={{ position: "fixed" }}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" color="inherit" noWrap>
            {/* Impala QA ({user.name}) 0.06d {true && <span style={{ backgroundColor: "red", color: "white" }}>OFFLINE!</span>} */}
            QC APP
          </Typography>
          {userVerified && <Sync />}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip title="New Inspection">
              <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={handleInspection} color="inherit">
                <IconBoat />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sync">
              <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={handleSync} color="inherit">
                <IconSync />
              </IconButton>
            </Tooltip>
            {navigator.onLine && (
              <Tooltip title="Notifications">
                <IconButton color="inherit" onClick={handleNotifications}>
                  <Badge badgeContent={badgeNum} color="secondary" style={{}}>
                    <IconMail />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            {navigator.onLine && (
              <Tooltip title="Logout">
                <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={handleLogout} color="inherit">
                  <IconLock />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ width: "100%", height: "55px" }}></div>
      <div style={{ height: "100vh", width: "100%" }}>{children}</div>
      <BottomNavigation
        value={tabValue}
        onChange={(event, newValue) => {
          handleChangeNav(newValue);
        }}
        showLabels
        className={classes.bottomNavWrapper}
      >
        <BottomNavigationAction label="Home" icon={<IconHome />} />
        <BottomNavigationAction label="Inspections" icon={<IconReport />} />
        <BottomNavigationAction label="Pack Instructions" icon={<IconOrder />} />
      </BottomNavigation>
    </div>
  );
};

export const IconOrder = () => (
  <SvgIcon x="0px" y="0px" viewBox="0 0 482 482">
    <g>
      <g>
        <polygon points="279.8,244.8 258.2,257.3 258.2,482 452.7,369.7 452.7,145 		" />
        <polygon points="315,43.3 240.2,0 40.3,115.4 115.2,158.7 		" />
        <polygon points="440,115.4 353.8,66.3 154,181.7 165.4,187.6 240.2,230.8 314.6,187.9 		" />
        <polygon points="138.9,264.3 103.1,245.9 103.1,188.7 29.3,146.2 29.3,369.3 222.4,480.8 222.4,257.7 138.9,209.6 		" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export const IconBoat = () => (
  <SvgIcon x="0px" y="0px" width="434.02px" height="434.02px" viewBox="0 0 434.02 434.02">
    <g>
      <path
        d="M433.808,73.223c-0.451-1.33-1.563-2.326-2.938-2.625L262.392,33.893c-1.366-0.301-2.786,0.139-3.748,1.15l-52.703,55.392
		l-59.988-54.614c-1.244-1.132-3.066-1.364-4.555-0.582L2.138,108.453c-1.119,0.589-1.895,1.673-2.09,2.923s0.211,2.52,1.1,3.422
		l57.094,58.098l-0.039,188.633c0,1.908,1.346,3.552,3.215,3.925L235,400.144c0.262,0.051,0.523,0.076,0.785,0.076
		c0.916,0,1.813-0.313,2.535-0.906c0.928-0.76,1.465-1.895,1.465-3.094V217.047c0-1.912-1.354-3.557-3.229-3.926l-24.969-4.912
		V103.136l162.807,39.354l-120.891,68.711c-1.252,0.711-2.022,2.039-2.022,3.477v176.769c0,1.413,0.745,2.725,1.965,3.442
		c0.627,0.371,1.33,0.558,2.035,0.558c0.664,0,1.327-0.166,1.928-0.496l125.762-69.238c1.277-0.703,2.07-2.045,2.07-3.502
		l0.062-188.752l47.771-56.365C433.978,76.022,434.259,74.553,433.808,73.223z M201.111,206.145l-130.867-25.76l130.867-74.871
		V206.145z M351.026,252.156c-0.123,1.27-0.858,2.397-1.971,3.021l-10.518,5.908l-0.168,41.555c-0.006,1.404-0.767,2.695-1.99,3.384
		l-20.115,11.293c-0.592,0.332-1.25,0.498-1.908,0.498c-0.684,0-1.366-0.181-1.977-0.537c-1.195-0.703-1.928-1.99-1.922-3.377
		l0.149-37.136l-6.854,3.849c-1.496,0.84-3.371,0.602-4.609-0.59c-1.239-1.189-1.553-3.054-0.771-4.582l22.776-44.621
		c0.543-1.063,1.541-1.818,2.712-2.052c1.17-0.232,2.383,0.082,3.29,0.855l22.521,19.188
		C350.646,249.639,351.149,250.889,351.026,252.156z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export default withStyles(styles)(DashboardUnstyled);

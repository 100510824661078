import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { genericApi } from "../lib/genericAPI";
import { inspectiontype } from "../lib/storage/inspection";

import history from "../lib/history";

import { DialogInformation } from "../lib/components/dialoginformation";

import InspectionsLocal from "../inspections/inspectionslocal";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

type SyncMasterProps = {
} & WithStyles<typeof styles>;

const SyncMasterUnstyled: React.SFC<SyncMasterProps> = (props) => {
  const { classes } = props;

  const [confirmopen, setConfirmopen] = React.useState(false);
  const [confirmTitle, setConfirmTitle] = React.useState("");
  const [confirmBody, setConfirmBody] = React.useState("");
  useEffect(() => {
  }, [classes])

  const handleClearInspections = () => {
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.all().then(data => {
      data.map(item => {
        inspectionsGeneric.remove(item.id);
      })
    });

    setConfirmTitle("Clear Inspections");
    setConfirmBody("You have reset the local inspections");
    setConfirmopen(true);
  }

  const handleResetSync = () => {
    localStorage.setItem("lastSync", "0");
    setConfirmTitle("Reset Current Sync");
    setConfirmBody("You have reset the current Sync values");
    setConfirmopen(true);
  }

  const handleConfirm = () => {
    setConfirmopen(false);
    history.push("/");
    return;
  }

  return (
    <div className={classes.root}>
      <DialogInformation 
        isOpen={confirmopen} 
        handleClose={handleConfirm}
        handleOK={handleConfirm}
        title={confirmTitle}
        body={confirmBody}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleClearInspections}
        className={classes.button}
      >
        clear inspections
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleResetSync}
        className={classes.button}
      >
        reset sync
      </Button>
      <InspectionsLocal status={2} />
    </div>
  )
}

export default withStyles(styles)(SyncMasterUnstyled);
import React, { useEffect, useState } from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";

import { genericApi } from "../lib/genericAPI";
import { piordergroupedtype } from "../lib/storage/piordergrouped";
import { weekstype } from "../lib/storage/week";

import { subDays, format, addDays } from "date-fns";

import { producertype } from "../lib/storage/producers";
import { ComboSelection } from "../lib/components/ComboSelection";
import { printInstructionPDF } from "../reports/printPackInstruction";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "10px",
      marginTop: "10px",
    },
    noDataMessage: {
      color: "#444",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "10px",
    },
    orderCardsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: `${theme.spacing.unit * 3}px`,
      marginTop: theme.spacing.unit * 4,
    },
    orderCard: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: `${theme.spacing.unit}px`,
      paddingRight: `${theme.spacing.unit}px`,
    },
    orderCardDetails: {
      display: "flex",
      flexDirection: "column",
      color: "#444",
      "& p:first-child": {
        fontWeight: "bold",
        fontSize: "18px",
        margin: 0,
      },
      "& p:last-child": {
        color: "#555",
        fontSize: "14px",
        margin: 0,
      },
    },
    pdfButton: {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "none",
    },
  });

type PackInstructionProps = {} & WithStyles<typeof styles>;

const PackInstructionsUnstyled: React.FC<PackInstructionProps> = ({ children, classes }) => {
  const [orders, setOrders] = useState<piordergroupedtype[]>([]);
  const [producers, setProducers] = useState([]);
  const [selectedProducer, setSelectedProducer] = useState(0);

  const loadData = async () => {
    const producersGeneric = genericApi<producertype>("producers");
    const producersData = await producersGeneric.all();
    const producersCombo = producersData.map((producer) => ({ id: producer.orgid, code: producer.orgid, name: producer.name })).sort((a, b) => a.name.localeCompare(b.name));

    setProducers(producersCombo);
  };

  const getOrdersByProducer = async (dateSelected = undefined) => {
    const piordergroupedGeneric = genericApi<piordergroupedtype>("piordergrouped");
    const weeksGeneric = genericApi<weekstype>("weeks");

    const weeksData = await weeksGeneric.all();
    const orderData = await piordergroupedGeneric.all();

    if (dateSelected) {
      const nextWeek = weeksData.find((week) => addDays(new Date(), 7) >= new Date(week.period_start) && addDays(new Date(), 7) <= new Date(week.period_end));
      const currentWeek = weeksData.find((week) => new Date() >= new Date(week.period_start) && new Date() <= new Date(week.period_end));
      const previousWeek = weeksData.find((week) => subDays(new Date(), 7) >= new Date(week.period_start) && subDays(new Date(), 7) <= new Date(week.period_end));

      const filteredData = orderData.filter(
        (order) => parseInt(order.producer_id) == selectedProducer && (order.week == nextWeek.week || order.week == currentWeek.week || order.week == previousWeek.week),
      );

      setOrders(filteredData);
    } else {
      const filteredData = orderData.filter((order) => parseInt(order.producer_id) == selectedProducer).slice(0, 20);
      setOrders(filteredData);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (selectedProducer != 0) {
      getOrdersByProducer(new Date());
    }
  }, [selectedProducer]);

  const onSelectProducer = (producer_id: number) => {
    setSelectedProducer(producer_id);
  };

  const handlePrintPDF = (order_id: number) => {
    printInstructionPDF(order_id);
  };

  return (
    <div className={classes.root}>
      <ComboSelection classes={{}} name="Producers" selected={selectedProducer} setSelected={onSelectProducer} data={producers} />
      {selectedProducer != 0 && orders.length === 0 && (
        <div className={classes.noDataMessage}>
          <p>No orders found for the current and previous week.</p>
          <Button color="secondary" variant="contained" onClick={() => getOrdersByProducer()}>
            Load older data
          </Button>
        </div>
      )}
      <div className={classes.orderCardsWrapper}>
        {orders.map((item) => (
          <div className={classes.orderCard}>
            <div className={classes.orderCardDetails}>
              <p>{item.ordernum}</p>
              <p>Last updated: {format(new Date(item.updated_at), "EEEE, dd MMMM yyyy, HH:mm:ss")}</p>
            </div>
            <Button className={classes.pdfButton} variant="contained" onClick={() => handlePrintPDF(parseFloat(item.id))}>
              <PDFIcon />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const PDFIcon = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="3" stroke="#fd4233" enable-background="new 0 0 128 128" viewBox="0 0 128 128">
      <path
        fill="#fd4233"
        d="M104,126H24c-5.514,0-10-4.486-10-10V12c0-5.514,4.486-10,10-10h40.687      c2.671,0,5.183,1.041,7.07,2.929l39.314,39.314c1.889,1.889,2.929,4.399,2.929,7.07V116C114,121.514,109.514,126,104,126z M24,6      c-3.309,0-6,2.691-6,6v104c0,3.309,2.691,6,6,6h80c3.309,0,6-2.691,6-6V51.313c0-1.579-0.641-3.125-1.757-4.242L68.929,7.757      C67.796,6.624,66.289,6,64.687,6H24z"
      />
      <path
        fill="#ff402f"
        d="M95.21,80.32c-0.07-0.51-0.48-1.15-0.92-1.58c-1.26-1.24-4.03-1.89-8.25-1.95      c-2.86-0.03-6.3,0.22-9.92,0.73c-1.62-0.93-3.29-1.95-4.6-3.18c-3.53-3.29-6.47-7.86-8.31-12.89c0.12-0.47,0.22-0.88,0.32-1.3      c0,0,1.98-11.28,1.46-15.1c-0.07-0.52-0.12-0.67-0.26-1.08l-0.17-0.44c-0.54-1.25-1.6-2.57-3.26-2.5L60.32,41H60.3      c-1.86,0-3.36,0.95-3.76,2.36c-1.2,4.44,0.04,11.09,2.29,19.69l-0.58,1.4c-1.61,3.94-3.63,7.9-5.41,11.39l-0.23,0.45      c-1.88,3.67-3.58,6.79-5.13,9.43l-1.59,0.84c-0.12,0.06-2.85,1.51-3.49,1.89c-5.43,3.25-9.03,6.93-9.63,9.85      c-0.19,0.94-0.05,2.13,0.92,2.68l1.54,0.78c0.67,0.33,1.38,0.5,2.1,0.5c3.87,0,8.36-4.82,14.55-15.62      c7.14-2.32,15.28-4.26,22.41-5.32c5.43,3.05,12.11,5.18,16.33,5.18c0.75,0,1.4-0.07,1.92-0.21c0.81-0.22,1.49-0.68,1.91-1.3      C95.27,83.76,95.43,82.06,95.21,80.32z M36.49,99.33c0.7-1.93,3.5-5.75,7.63-9.13c0.26-0.21,0.9-0.81,1.48-1.37      C41.28,95.72,38.39,98.46,36.49,99.33z M60.95,43c1.24,0,1.95,3.13,2.01,6.07c0.06,2.94-0.63,5-1.48,6.53      c-0.71-2.26-1.05-5.82-1.05-8.15C60.43,47.45,60.38,43,60.95,43z M53.65,83.14c0.87-1.55,1.77-3.19,2.69-4.92      c2.25-4.25,3.67-7.57,4.72-10.3c2.1,3.82,4.72,7.07,7.79,9.67c0.39,0.32,0.8,0.65,1.22,0.98C63.82,79.8,58.41,81.31,53.65,83.14      z M93.08,82.79c-0.38,0.23-1.47,0.37-2.17,0.37c-2.26,0-5.07-1.03-9-2.72c1.51-0.11,2.9-0.17,4.14-0.17      c2.27,0,2.94-0.01,5.17,0.56C93.44,81.4,93.47,82.55,93.08,82.79z"
      />
    </svg>
  </SvgIcon>
);

export default withStyles(styles)(PackInstructionsUnstyled);

import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import ReactDatePicker from "react-datepicker";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing.unit,
  },
  datepicker: {
    width: "200px",
    height: "40px",
    outline: "none",
    border: "1px solid #c4c4c4",
    borderRadius: `${theme.spacing.unit / 2}px`,
    fontSize: `${theme.typography.fontSize}px`,
    padding: `0 ${theme.spacing.unit}px`
  },
});

type DatePickerProps = {
  selectedDate: any, 
  setSelectedDate: any,
} & WithStyles<typeof styles>;

const DatePickerUnstyled: React.SFC<DatePickerProps> = (props) => {
  const { selectedDate, setSelectedDate, classes } = props;

  return (
    <div className={classes.root}>
      <ReactDatePicker
        selected={selectedDate}
        onChange={(value) => {
          setSelectedDate(value);
        }}
        className={classes.datepicker}
        // dateFormat="dd MMMM YYYY"
        dateFormat="dd MMMM yyyy"
      />
    </div>
  )
}

export const DatePicker = withStyles(styles)(DatePickerUnstyled);

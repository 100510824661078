import { palletspectype } from "../lib/api/palletspec";
import { genericApi } from "../lib/genericAPI";
import { piinstructiontype } from "../lib/storage/piinstructions";
import { piordergroupedtype } from "../lib/storage/piordergrouped";
import { producertype } from "../lib/storage/producers";
import { varietiestype } from "../lib/storage/varieties";
import { weekstype } from "../lib/storage/week";
import { handlePrintPDF } from "./packinginstruction";

export const printInstructionPDF = async (piorderid) => {
  const varieties = genericApi<varietiestype>("varieties");
  const weeks = genericApi<weekstype>("weeks");
  const piorder = genericApi<piordergroupedtype>("piordergrouped");
  const producers = genericApi<producertype>("producers");
  const palletspecGeneric = genericApi<palletspectype>("palletspecs");
  const piinstructionGeneric = genericApi<piinstructiontype>("piinstructions");

  const [resultVarieties, resultWeeks, resultPIOrder, resultProducers] = await Promise.all([
    varieties.all(),
    weeks.all(),
    await piorder.findBy("id", piorderid),
    await producers.all(),
  ]);

  const resultPalletspecs = await palletspecGeneric.all();

  resultPIOrder.exchange = JSON.parse(resultPIOrder.exchange);

  let sequencedOrders;

  sequencedOrders = { data: [resultPIOrder] };

  Promise.all(
    sequencedOrders.data.map(async (sequencedOrder) => {
      const resultInstructions = [];

      await piinstructionGeneric.filterBy("orgid", sequencedOrder.orgid).then((data) => {
        data.map((item, idx) => {
          if (!item.rowKey) {
            item.rowKey = idx + 1;
          }
          if (item.count[0] == "/") {
            item.count = item.count.substring(1);
          }

          Object.keys(item).map((key) => {
            if (item[key] == null) {
              item[key] = "";
            }
          });

          resultInstructions.push(item);
        });
      });

      sequencedOrder.instructions = resultInstructions;
      return sequencedOrder;
    }),
  ).then(() => {
    return handlePrintPDF(resultPIOrder, sequencedOrders, resultPalletspecs, resultProducers, resultWeeks, resultVarieties);
  });
};

import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";

// const styles = (theme: Theme) => createStyles({
//   root: {
//     padding: theme.spacing.unit,
//   },
//   comboselectionform: {
//     // padding: theme.spacing.unit,
//   },
//   selectEmpty: {
//     // marginTop: theme.spacing.unit,
//     width: "100%",
//     maxWidth: "250px",
//   },
// });

type ComboSelectionProps = {
  classes: any;
  className?: string;
  name: string;
  selected: any;
  setSelected: any;
  data: any;
  addNone?: boolean;
  locked?: boolean;
} & WithStyles;

const ComboSelectionUnstyled: React.SFC<ComboSelectionProps> = (props) => {
  const { name, selected, setSelected, data, addNone = true, classes, locked = false, className = undefined } = props;

  return (
    <div className={classes.root}>
      <FormControl className={classes.comboselectionform}>
        <NativeSelect
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          name={name}
          className={className || classes.selectEmpty}
          disabled={locked}
        >
          {addNone && (
            <option key={`${name}none`} value="">
              None
            </option>
          )}
          {data.map((item) => {
            return (
              <option key={`${name}${item.id}`} value={item.code}>
                {item.name}
              </option>
            );
          })}
        </NativeSelect>
        <FormHelperText>{`${name.toUpperCase()}`}</FormHelperText>
      </FormControl>
    </div>
  );
};

export const ComboSelection = ComboSelectionUnstyled;

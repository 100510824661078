import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import moment from "moment";

import { DatePicker } from "../lib/components/DatePicker";
import { ComboSelection } from "../lib/components/ComboSelection";

import { genericApi } from "../lib/genericAPI";
import { producertype } from "../lib/storage/producers";
import { inspectiontype } from "../lib/storage/inspection";
import { puctype } from "../lib/storage/pucs";
import { orchardtype } from "../lib/storage/orchard";
import { varietiestype } from "../lib/storage/varieties";
import { weekstype } from "../lib/storage/week";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: `${theme.spacing.unit * 3}px`,
      paddingRight: `${theme.spacing.unit * 3}px`,
      gap: `${theme.spacing.unit * 2}px`,
    },
    textfield: {
      padding: "0, 0, 0, 0", // theme.spacing.unit * 2
      width: "100%",
    },
    comboselectionform: {
      width: "100%",
    },
    datepicker: {
      width: "100%",
    },
    root: {
      width: "100%",
      padding: 0,
      "& .react-datepicker-wrapper": {
        width: "100%",
        "& .react-datepicker__input-container": {
          width: "100%",
        },
      },
    },
  });

type Inspection1Props = {
  inspectionid: string;
} & WithStyles<typeof styles>;

const Inspection1Unstyled: React.SFC<Inspection1Props> = (props) => {
  const { classes, inspectionid } = props;

  const [inspectionDate, setInspectionDate] = React.useState(new Date());
  const [producersData, setProducersData] = React.useState([]);
  const [pucData, setPucData] = React.useState([]);
  const [orchardData, setOrchardData] = React.useState([]);
  const [varietiesData, setVarietiesData] = React.useState([]);
  const [commodityData, setCommodityData] = React.useState([]);
  const [varietyData, setVarietyData] = React.useState([]);
  const [weeksData, setWeeksData] = React.useState([]);
  const [locked, setLocked] = React.useState(false);

  const [state, setState] = React.useState({
    id: inspectionid,
    producer: undefined,
    puc: undefined,
    orchard: undefined,
    pickref: "",
    processed: 0,
    inspectiondate: moment(new Date()).utc().valueOf(),
    username: "",
    commodity_code: undefined,
    variety_id: undefined,
    week_id: 0,
  });

  useEffect(() => {
    loadData();
  }, [inspectionid]);

  const loadData = async () => {
    const currentUser = window.localStorage.getItem("currentUser");
    const currentUserJSON = JSON.parse(currentUser);
    setState({ ...state, username: currentUserJSON.username });

    const producersGeneric = genericApi<producertype>("producers");
    producersGeneric.all().then((producersLocal) => {
      const resultProducers = producersLocal.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      });
      setProducersData(resultProducers);

      const varietiesGeneric = genericApi<varietiestype>("varieties");
      varietiesGeneric.all().then((varietiesLocal) => {
        setVarietiesData(varietiesLocal);

        const result = Array.from(new Set(varietiesLocal.map((varLocalItem) => varLocalItem.commodity)))
          .map((commodity) => {
            return {
              id: commodity,
              code: commodity,
              name: varietiesLocal.find((vl) => vl.commodity == commodity).commodity_name,
            };
          })
          .sort((a, b) => {
            if (a.code > b.code) return 1;
            if (a.code < b.code) return -1;
            return 1;
          });
        setCommodityData(result);

        const weeksGeneric = genericApi<weekstype>("weeks");
        weeksGeneric.all().then((weeksLocal) => {
          setWeeksData(
            weeksLocal.sort((a, b) => {
              if (a.week.replace(" ", "") > b.week.replace(" ", "")) return -1;
              if (a.week.replace(" ", "") < b.week.replace(" ", "")) return 1;
              return 1;
            }),
          );

          // LOAD existing
          const inspectionsGeneric = genericApi<inspectiontype>("inspections");
          inspectionsGeneric.findBy("id", inspectionid).then((data) => {
            if (data) {
              setInspectionDate(moment(Number(data.inspectiondate)).toDate());

              const commoditycode = data.variety_id ? varietiesLocal.find((vl) => vl.orgid == data.variety_id) : { commodity: undefined };
              setState({
                id: inspectionid,
                producer: data.producer,
                puc: data.puc,
                orchard: data.orchard,
                pickref: data.pickref,
                processed: 0,
                inspectiondate: Number(data.inspectiondate),
                username: currentUserJSON.username,
                commodity_code: commoditycode.commodity,
                variety_id: data.variety_id,
                week_id: data.week_id,
              });
              filterPUC(data.producer);
              filterOrchard(data.puc);
              filterVariety(commoditycode.commodity, varietiesLocal);
              if (data.inspectionOrders && data.inspectionOrders.length > 0) {
                setLocked(true);
              }
            }
          });
        });
      });
    });
  };

  const onSelectionChange = (name) => (value) => {
    if (name == "pickref") {
      if (value.length > 4) {
        value = value.toString().substring(0, 4);
      }
    }

    const newState = { ...state, [name]: value };
    setState(newState);

    if (name == "producer") {
      newState.puc = undefined;
      newState.orchard = undefined;
      newState.pickref = "";
      filterPUC(newState.producer);
    }

    if (name == "puc") {
      filterOrchard(newState.puc);
    }

    if (name == "commodity_code") {
      filterVariety(newState.commodity_code, varietiesData);
    }
    const inspectionsGeneric = genericApi<inspectiontype>("inspections");

    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      const newData = {
        id: inspectionid,
        producer: newState.producer,
        puc: newState.puc,
        orchard: newState.orchard,
        pickref: newState.pickref,
        processed: newState.processed,
        username: newState.username,
        inspectiondate: newState.inspectiondate,
        variety_id: newState.variety_id,
        week_id: newState.week_id,
      };
      inspectionsGeneric.update(inspectionid, { ...data, ...newData }).then(() => {
        inspectionsGeneric.findBy("id", inspectionid).then((data) => {});
      });
    });
  };

  const filterPUC = (producerid) => {
    const pucsGeneric = genericApi<puctype>("pucs");
    pucsGeneric.all().then((pucsLocal) => {
      const resultpucs = pucsLocal
        .filter((item) => item.producer_id == producerid)
        .sort((a, b) => {
          if (a.code > b.code) return 1;
          if (a.code < b.code) return -1;
          return 1;
        });
      setPucData(resultpucs);
      setOrchardData([]);
    });
  };

  const filterOrchard = (pucid) => {
    const orchardsGeneric = genericApi<orchardtype>("orchards");
    orchardsGeneric.all().then((orchardsLocal) => {
      const resultorchards = orchardsLocal
        .filter((item) => item.puc_id == pucid)
        .sort((a, b) => {
          if (Number(a.code) > Number(b.code)) return 1;
          if (Number(a.code) < Number(b.code)) return -1;
          return 1;
        });
      setOrchardData(resultorchards);
    });
  };

  const filterVariety = (commodity_code, vardata) => {
    const result = vardata
      .filter((varDataItem) => varDataItem.commodity == commodity_code)
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((mapitem) => {
        return { id: mapitem.orgid, code: mapitem.orgid, name: `${mapitem.name} (${mapitem.code})` };
      });
    setVarietyData(result);
  };

  const onDateChange = (name) => (value) => {
    setInspectionDate(value);
    const newDate = moment(value).utc().valueOf();

    const inspectionsGeneric = genericApi<inspectiontype>("inspections");
    inspectionsGeneric.findBy("id", inspectionid).then((data) => {
      inspectionsGeneric.update(inspectionid, { ...data, inspectiondate: newDate }).then(() => {
        inspectionsGeneric.findBy("id", inspectionid).then((data) => {});
      });
    });
  };

  return (
    <div className={classes.container}>
      <DatePicker classes={classes} selectedDate={inspectionDate} setSelectedDate={onDateChange("inspectiondate")} />
      <ComboSelection
        classes={classes}
        name="producer"
        selected={state.producer}
        setSelected={onSelectionChange("producer")}
        data={producersData.map((item) => {
          return { id: item.id, code: item.orgid, name: item.name };
        })}
        locked={locked}
      />
      {/* <ComboSelection classes={classes} name="puc" selected={state.puc} setSelected={onSelectionChange("puc")} data={pucData.map(item => { return { id: item.id, code: item.orgid, name: item.code } })} />
        <ComboSelection classes={classes} name="orchard" selected={state.orchard} setSelected={onSelectionChange("orchard")} data={orchardData.map(item => { return { id: item.id, code: item.orgid, name: item.code } })} /> */}
      <OrchardPUC puc={state.puc} orchard={state.orchard} onSelectionChange={onSelectionChange} pucData={pucData} orchardData={orchardData} />

      <TextField
        className={classes.textfield}
        label={"PICK REF"}
        value={state.pickref}
        onChange={(e) => {
          onSelectionChange("pickref")(e.target.value);
        }}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
      />
      <CommodityVarietySelection
        commodity_code={state.commodity_code}
        variety_id={state.variety_id}
        onSelectionChange={onSelectionChange}
        commodityData={commodityData}
        varietyData={varietyData}
        locked={locked}
      />
      <ComboSelection
        classes={classes}
        name="Week"
        selected={state.week_id}
        setSelected={onSelectionChange("week_id")}
        data={weeksData.map((item) => {
          return { id: item.orgid, code: item.orgid, name: item.week };
        })}
        locked={locked}
      />
    </div>
  );
};

export default withStyles(styles)(Inspection1Unstyled);

const stylesOrchardPUC = (theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: `${theme.spacing.unit * 2}px`,
      width: "100%",
    },
    comboselectionform: {
      width: "100%",
    },
  });

type OrchardPUCProps = {
  puc: any;
  orchard: any;
  onSelectionChange: any;
  pucData: any;
  orchardData: any;
} & WithStyles<typeof stylesOrchardPUC>;

const OrchardPUCUnstyled: React.FC<OrchardPUCProps> = (props) => {
  const { classes, puc, orchard, onSelectionChange, pucData, orchardData } = props;

  return (
    <div className={classes.container}>
      <ComboSelection
        classes={classes}
        name="puc"
        selected={puc}
        setSelected={onSelectionChange("puc")}
        data={pucData.map((item) => {
          return { id: item.id, code: item.orgid, name: item.code };
        })}
      />
      <ComboSelection
        classes={classes}
        name="orchard"
        selected={orchard}
        setSelected={onSelectionChange("orchard")}
        data={orchardData.map((item) => {
          return { id: item.id, code: item.orgid, name: item.code };
        })}
      />
    </div>
  );
};

export const OrchardPUC = withStyles(stylesOrchardPUC)(OrchardPUCUnstyled);

const stylesCommodityVariety = (theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: `${theme.spacing.unit * 2}px`,
      width: "100%",
    },
    comboselectionform: {
      width: "100%",
    },
  });

type CommodityVarietyProps = {
  commodity_code: any;
  variety_id: any;
  onSelectionChange: any;
  commodityData: any;
  varietyData: any;
  locked: boolean;
} & WithStyles<typeof stylesCommodityVariety>;

const CommodityVarietySelectionUnstyled: React.SFC<CommodityVarietyProps> = (props) => {
  const { classes, commodity_code, variety_id, onSelectionChange, commodityData, varietyData, locked } = props;

  return (
    <div className={classes.container}>
      <ComboSelection classes={classes} name="Commodity" selected={commodity_code} setSelected={onSelectionChange("commodity_code")} data={commodityData} locked={locked} />
      <ComboSelection classes={classes} name="Variety" selected={variety_id} setSelected={onSelectionChange("variety_id")} data={varietyData} locked={locked} />
    </div>
  );
};

export const CommodityVarietySelection = withStyles(stylesCommodityVariety)(CommodityVarietySelectionUnstyled);
